import axios from "axios";

export const prints =  {
    namespaced: true,
    state: {
        formats: [],
        searchResults: null,
        searchParams: {},
    },
    getters: {
        getFormats: (state) => state.formats,
        searchResults: (state) => state.searchResults,
        searchParams: (state) => state.searchParams,
    },
    mutations: {
        setFormats(state, formats) {
            state.formats = formats
        },
        setSearchResults(state, searchResults) {
            state.searchResults = searchResults
        },
        setSearchParams(state, searchParams) {
            state.searchParams = searchParams
        },
    },
    actions: {
        async loadFormats(context) {
            if (context.getters.getFormats.length > 0) {
                return context.getters.getFormats
            }

            const host = 'api.mossbeachlife.com';
            const path = `/admin/prints/formats`;

            const getRequest = {
                method: 'GET',
                hostname: host,
                path: path,
                headers: {
                    host: host,
                },
            }

            const signedRequest = await context.dispatch('auth/signRequest', getRequest, {root: true});

            const headers = signedRequest.headers;
            delete headers['host'];

            const url = process.env.VUE_APP_API_URL + path;
            const response = await axios.get(url, {
                headers: headers
            });

            const formats = response.data;
            await context.commit('setFormats', formats);
            return formats
        },
        async clearSearch(context) {
            context.commit('setSearchParams', {})
            context.commit('setSearchResults', null)
        },
        async loadPrintDetail(context, printId) {
            // do query
            const host = 'api.mossbeachlife.com';
            const path = `/admin/prints/${printId}`;

            const getRequest = {
                method: 'GET',
                hostname: host,
                path: path,
                headers: {
                    host: host,
                },
                // Add query parameters to the request object
                // query: queryParams
            }

            const signedRequest = await context.dispatch('auth/signRequest', getRequest, {root: true});

            const headers = signedRequest.headers;
            delete headers['host'];

            const url = process.env.VUE_APP_API_URL + path;
            const response = await axios.get(url, {
                headers: headers
            });

            return response.data;
        },
        async loadFormatGroup(context, payload) {

            const {format} = payload

            const queryParams = {
                format: format
            }

            // do query
            const host = 'api.mossbeachlife.com';
            const path = '/admin/prints';

            let pathWithQuery = path
            if (queryParams) {
                const queryString = new URLSearchParams(queryParams).toString();
                if (queryString) {
                    pathWithQuery = `${path}?${queryString}`;
                }
            }

            const getRequest = {
                method: 'GET',
                hostname: host,
                path: path,
                headers: {
                    host: host,
                },
                // Add query parameters to the request object
                query: queryParams
            }

            const signedRequest = await context.dispatch('auth/signRequest', getRequest, {root: true});

            const headers = signedRequest.headers;
            delete headers['host'];

            const url = process.env.VUE_APP_API_URL + pathWithQuery;
            const response = await axios.get(url, {
                headers: headers
            });

            const items = response.data.items;
            if (items.length > 1) {
                throw new Error('Expecting at most 1 result for format['+format+'], found ' + items.length);
            } else if (items.length > 0) {
                return items[0];
            } else {
                return null;
            }
        },
        async searchPrints(context, payload) {
            if (payload.page) {
                if (context.getters.searchResults) {
                    const searchResults = context.getters.searchResults
                    if (searchResults.pages) {
                        const pages = searchResults.pages;
                        const page = payload.page.toString();
                        if(pages[page]) {
                            return pages[page];
                        }
                    }
                }
            }

            const queryParams = payload.filters || context.getters.searchParams || {};
            await context.commit('setSearchParams', queryParams)

            // do not store page as part of locally stored searchParams
            if (payload.page) {
                queryParams.page = payload.page.toString();
            }

            // do query
            const host = 'api.mossbeachlife.com';
            const path = '/admin/prints';

            let pathWithQuery = path
            if (queryParams) {
                const queryString = new URLSearchParams(queryParams).toString();
                if (queryString) {
                    pathWithQuery = `${path}?${queryString}`;
                }
            }

            const getRequest = {
                method: 'GET',
                hostname: host,
                path: path,
                headers: {
                    host: host,
                },
                // Add query parameters to the request object
                query: queryParams
            }

            const signedRequest = await context.dispatch('auth/signRequest', getRequest, {root: true});

            const headers = signedRequest.headers;
            delete headers['host'];

            const url = process.env.VUE_APP_API_URL + pathWithQuery;
            const response = await axios.get(url, {
                headers: headers
            });

            const pagination = response.data.pagination;
            const currentPage = pagination.currentPage;
            const totalPages = pagination.totalPages;

            const items = response.data.items;

            const searchResults = context.getters.searchResults;
            if (searchResults) {
                searchResults.pages[currentPage] = items;
                await context.commit('setSearchResults', searchResults);
            } else {
                const results = {
                    totalPages: totalPages,
                    pages: {
                        // is this key a string?
                        [currentPage]: items
                    }
                }
                await context.commit('setSearchResults', results);
            }

            return items;
        },
        async updateFormatGroup(context, payload) {
            const updatePromises = payload.prints.map(async (print) => {
                return await context.dispatch('updatePrintDetail', {
                    printId: print.printId,
                    printDetail: {
                        ...payload,
                        printId: print.printId,
                    }
                });
            });
            // Wait for all product updates to complete
            await Promise.all(updatePromises);
        },
        async updatePrintDetail(context, payload) {

            const {printId, printDetail} = payload;

            delete printDetail.printId
            const body = {
                print: printDetail
            }

            const host = 'api.mossbeachlife.com';
            const path = `/admin/prints/${printId}`;

            const putRequest = await context.dispatch('auth/getPUTRequest', {
                host: host,
                path: path,
                contentType: 'application/json',
                body: body
            }, {root: true});

            const signedRequest = await context.dispatch('auth/signRequest', putRequest, {root: true});

            const headers = signedRequest.headers;
            delete headers['host'];

            const url = process.env.VUE_APP_API_URL + path
            const response = await axios.put(url, body,{
                headers: headers
            });

            return response.data;
        },
        async createPrint(context, print) {

            const body = {
                prints: [print]
            }

            const host = 'api.mossbeachlife.com';
            const path = `/admin/prints`;

            const postRequest = await context.dispatch('auth/getPOSTRequest', {
                host: host,
                path: path,
                contentType: 'application/json',
                body: body
            }, {root: true});

            const signedRequest = await context.dispatch('auth/signRequest', postRequest, {root: true});

            const headers = signedRequest.headers;
            delete headers['host'];

            const url = process.env.VUE_APP_API_URL + path
            const response = await axios.post(url, body,{
                headers: headers
            });

            // add type to productTypes (if new)
            const formats = context.getters.getFormats;
            const newFormat = print.format;
            if (!formats.includes(newFormat)) {
                context.getters.getFormats.push(newFormat)
            }

            return response.data[0];
        },
        async deletePrint(context, printId) {

            // do query
            const host = 'api.mossbeachlife.com';
            const path = `/admin/prints/${printId}`;

            const getRequest = {
                method: 'DELETE',
                hostname: host,
                path: path,
                headers: {
                    host: host,
                },
                // Add query parameters to the request object
                // query: queryParams
            }

            const signedRequest = await context.dispatch('auth/signRequest', getRequest, {root: true});

            const headers = signedRequest.headers;
            delete headers['host'];

            const url = process.env.VUE_APP_API_URL + path;
            await axios.delete(url, {
                headers: headers
            });
        },
        async loadCatalogPreview(context) {
            // do query
            const host = 'api.mossbeachlife.com';
            const path = '/admin/prints/catalog';

            const getRequest = {
                method: 'GET',
                hostname: host,
                path: path,
                headers: {
                    host: host,
                },
            }
            const signedRequest = await context.dispatch('auth/signRequest', getRequest, {root: true});

            const headers = signedRequest.headers;
            delete headers['host'];

            const url = process.env.VUE_APP_API_URL + path;
            const response = await axios.get(url, {
                headers: headers
            });

            return response.data;
        },
        async publishCatalog(context) {
            const body = {}

            const host = 'api.mossbeachlife.com';
            const path = `/admin/prints/catalog`;

            const postRequest = await context.dispatch('auth/getPOSTRequest', {
                host: host,
                path: path,
                contentType: 'application/json',
                body: body
            }, {root: true});

            const signedRequest = await context.dispatch('auth/signRequest', postRequest, {root: true});

            const headers = signedRequest.headers;
            delete headers['host'];

            const url = process.env.VUE_APP_API_URL + path
            await axios.post(url, body,{
                headers: headers
            });
        }
    }
}