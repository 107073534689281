<template>

  <loading-modal v-if="isLoading" title="Searching..."/>

  <loading-modal v-if="isSaving" title="Saving..."/>

  <div class="search-result">
    <div class="result-container">
      <div class="details">
        <div v-if="expanded" class="expanded">
          <div v-if="isEditing">
            <search-result-detail-edit :print-result="printResult"  @editCompleted="editCompleted"/>
          </div>
          <div v-else>
            <search-result-detail-view :print-result="printResult"/>
          </div>
        </div>
        <div v-else class="minimized">
          <div class="info-row">
            <span class="label">Name:</span>{{printResult.name}}
          </div>
          <div class="info-row">
            <span class="label">Size:</span>{{printResult.size}}
          </div>
          <div class="info-row">
            <span class="label">Updated:</span>{{printResult.updated}}
          </div>
        </div>
      </div>
      <div class="toggle">
        <button class="btn btn-outline-secondary" @click="toggleDisplay" :disabled="!canExpand">
          {{expanded ? 'Collapse' : 'Expand'}}
        </button>
        <button v-if="expanded && !isEditing"
                class="btn btn-outline-secondary"
                @click="editPrint"
                :disabled="!canEdit">
          Edit
        </button>
        <button v-if="expanded && isEditing" class="btn btn-outline-danger" @click="cancelEditPrint">
          Cancel
        </button>
        <button v-if="expanded" class="btn btn-outline-secondary" @click="deletePrint">
          Delete
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import {ref, watch} from "vue";
import {useStore} from "vuex";
import LoadingModal from "@/components/LoadingModal.vue";
import SearchResultDetailEdit from "@/components/search/SearchResultDetailEdit.vue";
import SearchResultDetailView from "@/components/search/SearchResultDetailView.vue";

export default {
  components: {
    SearchResultDetailView,
    SearchResultDetailEdit,
    LoadingModal
  },
  props: {
    printResult: {
      type: Object,
      required: true
    },
    canExpand: {
      type: Boolean,
      required: false,
      default: true
    },
    canEdit: {
      type: Boolean,
      required: false,
      default: true
    }
  },
  setup(props, {emit}) {

    const store = useStore();

    const isLoading = ref(false);
    const isEditing = ref(false);
    const isSaving = ref(false);
    const expanded = ref(false);

    watch(() => props.canExpand, (newValue) => {
      if (!newValue) {
        expanded.value = false;
      }
    })

    const toggleDisplay = async () => {
      if (!props.canExpand) {
        return;
      }

      if (!expanded.value && !props.printResult.printDetail) {
        isLoading.value = true;
        try {
          const printDetail = await store.dispatch('prints/loadPrintDetail', props.printResult.printId);

          // todo: append details to props.printResult here, and update
          const updatedPrintResult = {
            ...props.printResult,
              printDetail: printDetail
          }
          await store.dispatch('search/updateSearchResultDetail', updatedPrintResult);

          // await store.dispatch('search/updateSearchResultDetail', printDetail);
        } finally {
          isLoading.value = false;
        }
      }

      expanded.value = !expanded.value;
    }

    const editPrint = () => {
      isEditing.value = true
      emit('startEditing')
    }

    const cancelEditPrint = () => {
      isEditing.value = false
      emit('editCompleted')
    }

    const deletePrint = async () => {
      try {
        isSaving.value = true;
        await store.dispatch('prints/deletePrint', props.printResult.printId);

        await store.dispatch('search/removeSearchResultDetail', props.printResult)

        emit('editCompleted')
      } finally {
        isSaving.value = false;
      }
    }

    const editCompleted = () => {
      isEditing.value = false;
      emit('editCompleted')
    }

    return {
      isLoading,
      isSaving,
      isEditing,
      expanded,
      toggleDisplay,
      editPrint,
      cancelEditPrint,
      deletePrint,
      editCompleted,
    }
  },
  emits: ['startEditing', 'editCompleted']
}
</script>
<style scoped>
.search-result {
  width: 100%;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.result-container {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.details {
  width: 90%;
}

.expanded {
  display: flex;
  flex-direction: column;
  gap: 5px; /* Space between rows */
  align-items: flex-start;
}

.minimized {
  display: flex;
  flex-direction: column;
  gap: 5px; /* Space between rows */
  align-items: flex-start;
}

.info-row {
  display: inline-block;
  white-space: nowrap;
}

.label {
  display: inline-block;
  font-weight: bold;
  width: 100px; /* Fixed width for labels */
  text-align: right; /* Right align the label text */
  margin-right: 8px;
}

.toggle {
  width: 10%;
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.btn {
  width: 100%;
}
</style>