<template>
  <div class=" container-fluid"> <!-- Added container-fluid -->
    <div class="row w-100"> <!-- Changed to row -->
      <div class="col-12"> <!-- Use Bootstrap's grid system -->
        <div class="d-flex w-100">
          <div class="flex-grow-1">

            <prints-create :formatGroup="formatGroup" @created="printAdded"/>

          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import {useStore} from "vuex";
  import PrintsCreate from "@/components/prints/PrintsCreate.vue";

  export default {
    components: {
      PrintsCreate,
    },
    props: {
      formatGroup: {
        type: Object,
        required: false
      }
    },
    setup(props, {emit}) {

      const store = useStore();

      const printAdded = async function (newPrint) {
          const searchResultDetail = {
            format: newPrint.format,
            formatDescription: newPrint.formatDescription,
            printId: newPrint.printId,
            name: newPrint.name,
            size: newPrint.size,
            created: newPrint.created,
            updated: newPrint.updated,
            printDetail: newPrint
          }

        await store.dispatch('search/addSearchResultDetail', searchResultDetail)

        emit('addComplete')
      }

      return {
        printAdded
      }
    },
    emits: ['addComplete']
  }
</script>
