<template>

  <loading-modal v-if="isSaving" title="Saving..."/>

  <div class=" container-fluid">
    <div class="row w-100">
      <div class="col-12">
        <div class="d-flex w-100">
          <div class="flex-grow-1">

            <!-- Name -->
            <div class="row align-items-center mb-2">
              <label for="printName" class="col-2 col-form-label">
                Name:
              </label>
              <div class="col-10">
                <input
                    id="printName"
                    v-model="editablePrintResult.name"
                    class="form-control"
                    type="text"
                    required
                />
              </div>
            </div>

            <!-- Size -->
            <div class="row align-items-center mb-2">
              <label for="size" class="col-2 col-form-label">
                Size:
              </label>
              <div class="col-10">
                <input
                    id="selectOption"
                    v-model="editablePrintResult.size"
                    class="form-control"
                    type="text"
                    required
                />
              </div>
            </div>

            <!-- Unit Price -->
            <div class="row align-items-center mb-2">
              <label for="unitPrice" class="col-2 col-form-label">
                Unit Price:
              </label>
              <div class="col-10">
                <input
                    id="unitPrice"
                    v-model="editablePrintResult.unitPrice"
                    class="form-control"
                    type="number"
                    min="0"
                    required
                />
              </div>
            </div>

            <!-- Finishes -->
            <div class="row mb-2">
              <label for="finishes" class="col-2 col-form-label">
                Finishes:
              </label>
              <div class="col-10">
                <!-- Rows of features, with option to remove each row -->
                <div v-for="(finish, index) in editablePrintResult.finishes" :key="index" class="d-flex mb-2">
                  <input
                      :id="'finish-' + index"
                      v-model="editablePrintResult.finishes[index]"
                      type="text"
                      class="form-control me-2"
                      placeholder="Enter Finish"
                  />
                  <button
                      class="btn btn-outline-danger"
                      @click.prevent="removeFinish(index)"
                  >
                    Remove
                  </button>
                </div>

                <!-- Button to add new row -->
                <div class="text-end">
                  <button
                      class="btn btn-outline-secondary"
                      @click.prevent="addFinish"
                  >
                    Add Finish
                  </button>
                </div>
              </div>
            </div>

            <!-- Dimensions -->
            <div class="row align-items-center mb-2">
              <label for="length" class="col-2 col-form-label">
                Length (X):
              </label>
              <div class="col-10">
                <input
                    id="length"
                    v-model="editablePrintResult.length"
                    class="form-control"
                    type="number"
                    min="0"
                    required/>
              </div>
            </div>
            <div class="row align-items-center mb-2">
              <label for="width" class="col-2 col-form-label">
                Width (Y):
              </label>
              <div class="col-10">
                <input
                    id="width"
                    v-model="editablePrintResult.width"
                    class="form-control"
                    type="number"
                    min="0"
                    required/>
              </div>
            </div>
            <div class="row align-items-center mb-2">
              <label for="height" class="col-2 col-form-label">
                Thickness (Z):
              </label>
              <div class="col-10">
                <input
                    id="height"
                    v-model="editablePrintResult.height"
                    class="form-control"
                    type="number"
                    min="0"
                    required/>
              </div>

            </div>
            <div class="row align-items-center mb-2">
              <label for="weight" class="col-2 col-form-label">
                Weight (oz):
              </label>
              <div class="col-10">
                <input
                    id="width"
                    v-model="editablePrintResult.width"
                    class="form-control"
                    type="number"
                    min="0"
                    required/>
              </div>
            </div>

            <!-- Inventory -->
            <div class="row align-items-center mb-2">
              <label for="inventory" class="col-2 col-form-label">
                Inventory:
              </label>
              <div class="col-10">
                <input
                    id="inventory"
                    v-model="editablePrintResult.inventory"
                    type="number"
                    class="form-control"
                    min="-1"/>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row w-100">
      <div class="col-12">
        <button class="btn btn-outline-primary" @click.prevent="savePrintEdit">Save</button>
      </div>
    </div>
  </div>
</template>
<script>
import LoadingModal from "@/components/LoadingModal.vue";
import {ref} from "vue";
import {useStore} from "vuex";

export default {
  components: {LoadingModal},
  props: {
    printResult: {
      type: Object,
      required: true
    }
  },
  setup(props, {emit}) {

    const store = useStore();

    const isSaving = ref(false)

    const editablePrintResult = ref({
        name: props.printResult.name,
        size: props.printResult.size,
        unitPrice: props.printResult.printDetail.unitPrice,
        finishes: JSON.parse(JSON.stringify(props.printResult.printDetail.finishes)),
        length: props.printResult.printDetail.length,
        width: props.printResult.printDetail.width,
        height: props.printResult.printDetail.height,
        weight: props.printResult.printDetail.weight,
        inventory: props.printResult.printDetail.inventory
    })

    const addFinish = function () {
      if (!editablePrintResult.value.finishes) {
        editablePrintResult.value.finishes = [];
      }
      editablePrintResult.value.finishes.push('');
    }

    const removeFinish = function(index) {
      editablePrintResult.value.finishes.splice(index, 1);
    }

    const savePrintEdit = async () => {
      try {
        isSaving.value = true

        //  update print
        const updatedPrint = await store.dispatch('prints/updatePrintDetail', {
          printId: props.printResult.printId,
          printDetail: {
            ...editablePrintResult.value
          }
        })

        await store.dispatch('search/replaceSearchResultDetail', {
          printId: updatedPrint.printId,
          name: updatedPrint.name,
          size: updatedPrint.size,
          created: updatedPrint.created,
          updated: updatedPrint.updated,
          printDetail: updatedPrint
        })

        emit('editCompleted')
      } finally {
        isSaving.value = false
      }


    }

    return {
      editablePrintResult,
      isSaving,
      addFinish,
      removeFinish,
      savePrintEdit
    }
  },
  emits: ['editCompleted']
}
</script>