<template>

  <loading-modal v-if="isSaving" title="Saving..."/>

  <div class=" container-fluid"> <!-- Added container-fluid -->
    <div class="row w-100"> <!-- Changed to row -->
      <div class="col-12"> <!-- Use Bootstrap's grid system -->
        <div class="d-flex w-100">
          <div class="flex-grow-1" style="width: calc(100% - 70px);"> <!-- Added fixed calculation for width -->

            <!-- Type field -->
            <div v-if="!addingToGroup" class="row align-items-center mb-2">
              <label for="groupType" class="col-form-label col-2">Type:</label>
              <div class="col-10">
                  <input
                      id="groupType"
                      v-model="editableProduct.type"
                      class="form-control"
                      type="text"
                      placeholder="Enter Type"
                      required
                  />
              </div>
            </div>

            <!-- Group field -->
            <div v-if="!addingToGroup" class="row align-items-center mb-2">
              <label for="groupType" class="col-form-label col-2">Group Name:</label>
              <div class="col-10">
                <input
                    id="groupType"
                    v-model="editableProduct.group"
                    class="form-control"
                    type="text"
                    placeholder="Enter Group Name"
                    required
                />
              </div>
            </div>

            <!-- Name field -->
            <div class="row align-items-center mb-2">
              <label for="productName" class="col-2 col-form-label">
                Name:
              </label>
              <div class="col-10">
                <input
                    id="productName"
                    v-model="editableProduct.name"
                    class="form-control"
                    type="text"
                    placeholder="Enter Product Name"
                    required
                />
              </div>
            </div>

            <!-- Group Image URL field with conditional display -->
            <div v-if="!addingToGroup"  class="row align-items-center mb-2">
              <div class="col-2 col-form-label">
                <img v-if="editableProduct.groupImageUrl"
                     :src="editableProduct.groupImageUrl"
                     style="height: auto; width: auto; max-height: 30px;"
                     class="object-fit-contain"/>
                <label v-else
                       for="groupImageUrl">Group Image URL:</label>
              </div>
              <div class="col-10">
                  <input
                      id="groupImageUrl"
                      v-model="editableProduct.groupImageUrl"
                      class="form-control"
                      type="text"
                      placeholder="Enter GROUP Image URL"
                      required
                  />
              </div>
            </div>

            <!-- Product Image URL field with conditional display -->
            <div class="row align-items-center mb-2">
              <div class="col-2 col-form-label">
                <img v-if="editableProduct.imageUrl"
                     :src="editableProduct.imageUrl"
                     style="height: auto; width: auto; max-height: 30px;"
                     class="object-fit-contain"/>
                <label v-else for="imageUrl">Product Image URL:</label>
              </div>
              <div class="col-10">
                <input
                    id="imageUrl"
                    v-model="editableProduct.imageUrl"
                    class="form-control"
                    type="text"
                    placeholder="Enter Product Image URL"
                    required
                />
              </div>
            </div>

            <!-- Description field -->
            <div v-if="!addingToGroup"  class="row mb-2">
              <label for="groupDescription" class="col-form-label col-2 mt-2">Description:</label>
              <div class="col-10">
                <textarea
                    id="groupDescription"
                    v-model="editableProduct.description"
                    class="form-control"
                    rows="10"
                    placeholder="Group Description"
                ></textarea>
              </div>
            </div>

            <!-- Select Name field -->
            <div v-if="!addingToGroup"  class="row align-items-center mb-2">
              <label for="selectName" class="col-form-label col-2">Select Name:</label>
              <div class="col-10">
                <input
                    id="selectName"
                    v-model="editableProduct.selectName"
                    class="form-control"
                    type="text"
                    placeholder="Select Name"
                    required
                />
              </div>
            </div>

            <!-- Select Option -->
            <div class="row align-items-center mb-2">
              <label for="selectOption" class="col-2 col-form-label">
                Select Option:
              </label>
              <div class="col-10">
                <input
                    id="selectOption"
                    v-model="editableProduct.selectOption"
                    class="form-control"
                    type="text"
                    required
                />
              </div>
            </div>

            <!-- Unit Price -->
            <div class="row align-items-center mb-2">
              <label for="unitPrice" class="col-2 col-form-label">
                Unit Price:
              </label>
              <div class="col-10">
                <input
                    id="unitPrice"
                    v-model="editableProduct.unitPrice"
                    class="form-control"
                    type="number"
                    min="0"
                    required
                />
              </div>
            </div>

            <!-- Product URL -->
            <div class="row align-items-center mb-2">
              <label for="productUrl" class="col-2 col-form-label">
                Product URL:
              </label>
              <div class="col-10">
                <input
                    id="unitPrice"
                    v-model="editableProduct.productUrl"
                    class="form-control"
                    type="url"
                />
              </div>
            </div>

            <!-- Features -->
            <div class="row mb-2">
              <label for="features" class="col-2 col-form-label">
                Features:
              </label>
              <div class="col-10">
                <!-- Rows of features, with option to remove each row -->
                <div v-for="(feature, index) in editableProduct.features" :key="index" class="d-flex mb-2">
                  <input
                      :id="'feature-' + index"
                      v-model="editableProduct.features[index]"
                      type="text"
                      class="form-control me-2"
                      placeholder="Enter feature"
                  />
                  <button
                      class="btn btn-outline-danger"
                      @click.prevent="removeFeature(index)"
                  >
                    Remove
                  </button>
                </div>

                <!-- Button to add new row -->
                <div class="text-end">
                  <button
                      class="btn btn-outline-secondary"
                      @click.prevent="addFeature"
                  >
                    Add Feature
                  </button>
                </div>
              </div>
            </div>

            <!-- Dimensions -->
            <div class="row align-items-center mb-2">
              <label for="length" class="col-2 col-form-label">
                Length (X):
              </label>
              <div class="col-10">
                <input
                    id="length"
                    v-model="editableProduct.dimLength"
                    class="form-control"
                    type="number"
                    min="0"
                    required
                />
              </div>

            </div>
            <div class="row align-items-center mb-2">
              <label for="width" class="col-2 col-form-label">
                Width (Y):
              </label>
              <div class="col-10">
                <input
                    id="width"
                    v-model="editableProduct.dimWidth"
                    class="form-control"
                    type="number"
                    min="0"
                    required
                />
              </div>
            </div>
            <div class="row align-items-center mb-2">
              <label for="height" class="col-2 col-form-label">
                Thickness (Z):
              </label>
              <div class="col-10">
                <input
                    id="height"
                    v-model="editableProduct.dimHeight"
                    class="form-control"
                    type="number"
                    min="0"
                    required
                />
              </div>
            </div>
            <div class="row align-items-center mb-2">
              <label for="weight" class="col-2 col-form-label">
                Weight (oz):
              </label>
              <div class="col-10">
                <input
                    id="width"
                    v-model="editableProduct.dimWeight"
                    class="form-control"
                    type="number"
                    min="0"
                    required
                />
              </div>
            </div>

            <!-- Special Message -->
            <div class="row align-items-center mb-2">
              <label for="specialMessage" class="col-2 col-form-label">
                Special Message:
              </label>
              <div class="col-10">
                <textarea
                    id="specialMessage"
                    v-model="editableProduct.specialMessage"
                    class="form-control"
                    rows="3"
                ></textarea>
              </div>
            </div>

            <!-- SoldOut -->
            <div class="row align-items-center mb-2">
              <label for="soldOut" class="col-2 col-form-label">
                Sold Out:
              </label>
              <div class="col-10 text-start">
                <!-- checkbox to indicate if sold out OR input for quantity available-->
                <input type="checkbox" id="soldOut" v-model="editableProduct.soldOut">
              </div>
            </div>

            <!-- Inventory -->
            <div class="row align-items-center mb-2">
              <label for="inventory" class="col-2 col-form-label">
                Inventory:
              </label>
              <div class="col-10">
                <input
                    id="inventory"
                    v-model="editableProduct.inventory"
                    class="form-control"
                    type="number"
                    min="-1"
                    :disabled="editableProduct.soldOut"
                />
              </div>
            </div>

            <div class="row align-items-center mb-2">
              <div class="col-12">
                <button class="btn btn-outline-secondary me-2" @click.prevent="clearForm">Cancel</button>
                <button class="btn btn-outline-primary" @click.prevent="saveChanges">Save</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>
<script>
import {onBeforeMount, ref, watch} from "vue";
import LoadingModal from "@/components/LoadingModal.vue";
import Swal from "sweetalert2";
import {useStore} from "vuex";
import {useRouter} from "vue-router";

export default {
  components: {LoadingModal},
  props: {
    productGroup: {
      type: Object,
      required: false
    },
  },
  setup(props, {emit}) {

    const store = useStore();
    const router = useRouter();

    const isSaving = ref(false);
    const editableProduct = ref({
      type: '',
      group: '',
      groupImageUrl: '',
      description: '',
      selectName: '',
      name: '',
      imageUrl: '',
      productUrl: '',
      unitPrice: 0,
      selectOption: '',
      dimWeight: 0,
      dimLength: 0,
      dimWidth: 0,
      dimHeight: 0,
      features: [],
      specialMessage: '',
      soldOut: false,
      inventory: -1,
    });
    const addingToGroup = ref(false);

    onBeforeMount(() => {
      if (props.productGroup) {

        addingToGroup.value = true;

        editableProduct.value.type = props.productGroup.type;
        editableProduct.value.group = props.productGroup.group;
        editableProduct.value.groupImageUrl = props.productGroup.groupImageUrl;
        editableProduct.value.description = props.productGroup.description;
        editableProduct.value.selectName = props.productGroup.selectName;
      }
    })

    const clearForm = function () {
      editableProduct.value = {
        type: '',
        group: '',
        groupImageUrl: '',
        description: '',
        selectName: '',
        name: '',
        imageUrl: '',
        productUrl: '',
        unitPrice: 0,
        selectOption: '',
        dimWeight: 0,
        dimLength: 0,
        dimWidth: 0,
        dimHeight: 0,
        features: [],
        specialMessage: '',
        soldOut: false,
        inventory: 0,
      };
    };

    const addFeature = function () {
      if (!editableProduct.value.features) {
        editableProduct.value.features = [];
      }
      editableProduct.value.features.push('');
    }

    const removeFeature = function(index) {
      editableProduct.value.features.splice(index, 1);
    }

    const saveChanges = async function () {
      try {
        isSaving.value = true;

        const newProduct = await store.dispatch('products/createProduct', editableProduct.value);

        if (addingToGroup.value) {
          isSaving.value = false;
          emit('created', newProduct);
        } else {
          await router.replace('/products/search')
        }
      } catch (error) {
        console.error('error: ' + error);
        new Swal({
          title: 'Error!',
          text: 'We encountered a problem: ' + error.message,
          icon: 'error'
        });
      } finally {
        isSaving.value = false;
      }
    };

    watch(() => editableProduct.value.soldOut, (newValue) => {
      if (newValue) {
        // When soldOut is checked, set inventory to 0 and type to 'out'
        editableProduct.value.inventory = 0;
      } else {
        // When soldOut is unchecked, set inventory to -1 (unlimited) and type to 'unlimited'
        editableProduct.value.inventory = -1;
      }
    })

    return {
      editableProduct,
      addingToGroup,
      addFeature,
      removeFeature,
      clearForm,
      saveChanges,
      isSaving
    }
  },
  emits: ['created'],
}
</script>

<style scoped>
.group-detail {
  width: 100%;
  padding: 0; /* Remove default container padding if needed */
}

.form-control {
  resize: vertical;
  min-height: 60px;
  width: 100%;
  max-width: 100%;
}

.d-flex {
  width: 100%;
}

/* Ensure the content area takes full width */
.flex-grow-1 {
  min-width: 0; /* Prevents flex item from overflowing */
}

.col-form-label {
  padding-top: 0;
  padding-bottom: 0;
  white-space: nowrap;
  text-align: end;
  font-weight: bolder;
}
</style>