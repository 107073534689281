<template>
  <div class="group-detail">
  <loading-modal v-if="isSaving" title="Saving..."/>

  <loading-modal v-if="isLoading" title="Loading..."/>

  <div class=" container-fluid"> <!-- Added container-fluid -->
    <div class="row w-100"> <!-- Changed to row -->
      <div v-if="isEditing" class="col-12"> <!-- Use Bootstrap's grid system -->
        <div class="d-flex w-100">
          <div class="flex-grow-1" style="width: calc(100% - 70px);"> <!-- Added fixed calculation for width -->
            <!-- Image URL field with conditional display -->
            <div class="row align-items-center mb-2">
              <div class="col-2">
                <img v-if="editableGroup.groupImageUrl"
                     :src="editableGroup.groupImageUrl"
                     style="height: auto; width: auto; max-height: 30px;"
                     class="object-fit-contain"/>
                <label v-else
                       for="groupImageUrl"
                       class="col-form-label">Image URL:</label>
              </div>
              <div class="col-10">
                <input
                    id="groupImageUrl"
                    v-model="editableGroup.groupImageUrl"
                    class="form-control"
                    type="text"
                    placeholder="Enter Image URL"
                    required
                />
              </div>
            </div>

            <!-- Type field -->
            <div class="row align-items-center mb-2">
              <label for="groupType" class="col-form-label col-2">Type:</label>
              <div class="col-10">
                <input
                    id="groupType"
                    v-model="editableGroup.type"
                    class="form-control"
                    type="text"
                    placeholder="Enter Type"
                    required
                />
              </div>
            </div>

            <!-- Group field -->
            <div class="row align-items-center mb-2">
              <label for="groupType" class="col-form-label col-2">Group Name:</label>
              <div class="col-10">
                <input
                    id="groupType"
                    v-model="editableGroup.group"
                    class="form-control"
                    type="text"
                    placeholder="Enter Group Name"
                    required
                />
              </div>
            </div>

            <!-- Description field -->
            <div class="row align-items-center mb-2">
              <label for="groupDescription" class="col-form-label col-2">Description:</label>
              <div class="col-10">
                <textarea
                    id="groupDescription"
                    v-model="editableGroup.groupDescription"
                    class="form-control"
                    rows="10"
                    placeholder="Group Description"
                ></textarea>
              </div>
            </div>

            <!-- Select Name field -->
            <div class="row align-items-center mb-2">
              <label for="selectName" class="col-form-label col-2">Select Name:</label>
              <div class="col-10">
                <input
                    id="selectName"
                    v-model="editableGroup.selectName"
                    class="form-control"
                    type="text"
                    placeholder="Select Name"
                />
              </div>
            </div>

            <button class="btn btn-outline-secondary me-2" @click.prevent="cancelEditing">Cancel</button>
            <button class="btn btn-outline-primary" @click.prevent="saveChanges">Save</button>
          </div>
        </div>
      </div>
      <div v-else class="col-12"> <!-- Use Bootstrap's grid system -->
        <div class="d-flex flex-column w-100 mb-3"> <!-- Changed to flex-column -->
          <div class="d-flex"> <!-- Added margin-bottom -->
            <img :src="groupData.groupImageUrl"
                 alt="Group Image"
                 style="height: auto; width: auto; max-height: 50px;"
                 class="object-fit-contain float-start me-3"/>
            <div class="group-details flex-grow-1" style="width: calc(100% - 70px);">
              <p class="text-start">{{ groupData.groupDescription }}</p>
              <p class="text-start"><span class="fw-bolder">Select Name:</span> {{ groupData.selectName }}</p>
            </div>
          </div>
          <!-- Button in its own div below -->
          <div class="d-flex justify-content-center">
            <button v-if="!isAdding" class="btn btn-outline-secondary" @click.prevent="edit">Edit Group</button>
            <button v-if="!isAdding && !isEditing" class="btn btn-outline-secondary" @click.prevent="addProduct">Add Product</button>
          </div>
        </div>
      </div>
    </div>
    <div v-if="isAdding">
      <div class="row w-100 mb-2">
        <div class="col-12">
          <div class="d-flex w-100">
            <div class="d-flex justify-content-center">
              <button class="btn btn-outline-secondary" @click.prevent="cancelAdd">Cancel Add</button>
            </div>
          </div>
        </div>
      </div>
      <div class="row w-100">
        <div class="col-12">
          <div class="d-flex w-100">
            <products-create  :product-group="groupData" @product-created="productCreated"></products-create>
          </div>
        </div>
      </div>
    </div>
  </div>
  </div>
</template>

<script>
import {useStore} from "vuex";
import {onBeforeMount, ref} from "vue";
import Swal from "sweetalert2";
import LoadingModal from "@/components/LoadingModal.vue";
import ProductsCreate from "@/components/products/ProductsCreate.vue";


export default {
  components: {ProductsCreate, LoadingModal},
  props: {
    groupType: {
      type: String,
      required: true
    },
    groupName: {
      type: String,
      required: true
    }
  },
  setup(props, {emit}) {
    const store = useStore();

    const isLoading = ref(false);
    const isEditing = ref(false);
    const isAdding = ref(false);

    const isSaving = ref(false);
    const editableGroup = ref({});

    const groupData = ref({})
    const groupProducts = ref([])

    onBeforeMount(async () => {
      try {
        isLoading.value = true;

        const payload = {
          type: props.groupType,
          group: props.groupName
        }

        const productGroup = await store.dispatch('products/loadProductGroup', payload);
        groupData.value = {
          ...productGroup,
        }
        groupProducts.value = productGroup.products
      } catch (error) {
        console.error('Exception loading product group', error);
        new Swal({
          title: 'Error!',
          text: 'We encountered a problem: ' + error.message,
          icon: 'error'
        });
      } finally {
        isLoading.value = false;
      }
    })

    const edit = function () {
      // Create a deep copy of the group object to avoid direct mutation
      editableGroup.value = {
        group: groupData.value.group,
        type: groupData.value.type,
        groupDescription: groupData.value.groupDescription,
        selectName: groupData.value.selectName,
        groupImageUrl: groupData.value.groupImageUrl,
        // Add any other properties you need to edit
      };
      isEditing.value = true;
    }

    const cancelEditing = function () {
      isEditing.value = false;
      // Clear the editable object
      editableGroup.value = {};
    }

    const saveChanges = async function () {
      try {
        isSaving.value = true;

        await store.dispatch('products/updateProductGroup', {
          group: editableGroup.value,
          products: groupProducts.value
        });

        isEditing.value = false;
        emit('updated', {
          groupType: props.groupType,
          oldGroupName: groupData.value.group,
          newGroupName: editableGroup.value.group
        });
      } catch (error) {
        console.error('Exception saving changes', error);
        new Swal({
          title: 'Error!',
          text: 'We encountered a problem: ' + error.message,
          icon: 'error'
        });
      } finally {
        isSaving.value = false;
      }
    }

    const addProduct = function () {
      isAdding.value = true;
    }

    const cancelAdd = function () {
      isAdding.value = false;
    }

    const productCreated = function (product) {
      groupProducts.value.push(product);
      isAdding.value = false;
    }

    return {
      isLoading,
      isEditing,
      isAdding,
      groupData,
      editableGroup,
      edit,
      addProduct,
      productCreated,
      cancelAdd,
      cancelEditing,
      saveChanges,
      isSaving
    }
  },
  emits: ['updated']
}
</script>
<style scoped>
.group-detail {
  width: 100%;
  padding: 0; /* Remove default container padding if needed */
}

.form-control {
  resize: vertical;
  min-height: 60px;
  width: 100%;
  max-width: 100%;
}

.d-flex {
  width: 100%;
}

/* Ensure the content area takes full width */
.flex-grow-1 {
  min-width: 0; /* Prevents flex item from overflowing */
}

.col-form-label {
  padding-top: 0;
  padding-bottom: 0;
  white-space: nowrap;
}
</style>