<template>

  <loading-modal v-if="isSaving" title="Saving..."/>

  <div class=" container-fluid"> <!-- Added container-fluid -->
    <div class="row w-100"> <!-- Changed to row -->
      <div class="col-12"> <!-- Use Bootstrap's grid system -->
        <div class="d-flex w-100">
          <div class="flex-grow-1" style="width: calc(100% - 70px);"> <!-- Added fixed calculation for width -->

            <!-- Format field -->
            <div v-if="!addingToFormat" class="row align-items-center mb-2">
              <label for="format" class="col-form-label col-2">Format:</label>
              <div class="col-10">
                <input
                    id="format"
                    v-model="editablePrint.format"
                    class="form-control"
                    type="text"
                    placeholder="Metal Wall Art Print"
                    required
                />
              </div>
            </div>

            <!-- Description field -->
            <div v-if="!addingToFormat" class="row mb-2">
              <label for="description" class="col-form-label col-2 mt-2">Description:</label>
              <div class="col-10">
                <textarea
                    id="description"
                    v-model="editablePrint.formatDescription"
                    class="form-control"
                    rows="10"
                    placeholder="Description"
                ></textarea>
              </div>
            </div>

            <!-- Name field -->
            <div class="row align-items-center mb-2">
              <label for="printName" class="col-2 col-form-label">
                Name:
              </label>
              <div class="col-10">
                <input
                    id="printName"
                    v-model="editablePrint.name"
                    class="form-control"
                    type="text"
                    placeholder="Enter Print Name"
                    required
                />
              </div>
            </div>

            <!-- Select Name field -->
            <div class="row align-items-center mb-2">
              <label for="selectName" class="col-form-label col-2">Size:</label>
              <div class="col-10">
                <input
                    id="selectName"
                    v-model="editablePrint.size"
                    class="form-control"
                    type="text"
                    placeholder="Size (8x10)"
                    required
                />
              </div>
            </div>

            <!-- Unit Price -->
            <div class="row align-items-center mb-2">
              <label for="unitPrice" class="col-2 col-form-label">
                Unit Price:
              </label>
              <div class="col-10">
                <input
                    id="unitPrice"
                    v-model="editablePrint.unitPrice"
                    class="form-control"
                    type="number"
                    min="0"
                    required
                />
              </div>
            </div>

            <!-- Finishes -->
            <div class="row mb-2">
              <label for="features" class="col-2 col-form-label">
                Finishes:
              </label>
              <div class="col-10">
                <!-- Rows of features, with option to remove each row -->
                <div v-for="(finish, index) in editablePrint.finishes" :key="index" class="d-flex mb-2">
                  <input
                      :id="'feature-' + index"
                      v-model="editablePrint.finishes[index]"
                      type="text"
                      class="form-control me-2"
                      placeholder="Enter Finish"
                  />
                  <button
                      class="btn btn-outline-danger"
                      @click.prevent="removeFinish(index)"
                  >
                    Remove
                  </button>
                </div>

                <!-- Button to add new row -->
                <div class="text-end">
                  <button
                      class="btn btn-outline-secondary"
                      @click.prevent="addFinish">
                    Add Finish
                  </button>
                </div>
              </div>
            </div>

            <!-- Dimensions -->
            <div class="row align-items-center mb-2">
              <label for="length" class="col-2 col-form-label">
                Length (X):
              </label>
              <div class="col-10">
                <input
                    id="length"
                    v-model="editablePrint.length"
                    class="form-control"
                    type="number"
                    min="0"
                    required
                />
              </div>

            </div>
            <div class="row align-items-center mb-2">
              <label for="width" class="col-2 col-form-label">
                Width (Y):
              </label>
              <div class="col-10">
                <input
                    id="width"
                    v-model="editablePrint.width"
                    class="form-control"
                    type="number"
                    min="0"
                    required
                />
              </div>
            </div>
            <div class="row align-items-center mb-2">
              <label for="height" class="col-2 col-form-label">
                Thickness (Z):
              </label>
              <div class="col-10">
                <input
                    id="height"
                    v-model="editablePrint.height"
                    class="form-control"
                    type="number"
                    min="0"
                    required
                />
              </div>
            </div>
            <div class="row align-items-center mb-2">
              <label for="weight" class="col-2 col-form-label">
                Weight (oz):
              </label>
              <div class="col-10">
                <input
                    id="width"
                    v-model="editablePrint.weight"
                    class="form-control"
                    type="number"
                    min="0"
                    required
                />
              </div>
            </div>

            <!-- Inventory -->
            <div class="row align-items-center mb-2">
              <label for="inventory" class="col-2 col-form-label">
                Inventory:
              </label>
              <div class="col-10">
                <input
                    id="inventory"
                    v-model="editablePrint.inventory"
                    class="form-control"
                    type="number"
                    min="-1"
                />
              </div>
            </div>

            <div class="row align-items-center mb-2">
              <div class="col-12">
                <button class="btn btn-outline-secondary me-2" @click.prevent="clearForm">Clear</button>
                <button class="btn btn-outline-primary" @click.prevent="saveChanges">Save</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>
<script>
import {
  // onBeforeMount,
  ref,
} from "vue";
import LoadingModal from "@/components/LoadingModal.vue";
import Swal from "sweetalert2";
import {useStore} from "vuex";
import {useRouter} from "vue-router";

export default {
  components: {LoadingModal},
  props: {
    formatGroup: {
      type: Object,
      required: false
    }
  },
  setup(props, {emit}) {

    const store = useStore();
    const router = useRouter();

    const addingToFormat = ref(!!props.formatGroup);
    const isSaving = ref(false);
    const editablePrint = ref({
      format: props.formatGroup?.format || '',
      formatDescription: props.formatGroup?.formatDescription || '',
      name: '',
      size: '',
      finishes: ['Glossy', 'Matte'],
      unitPrice: 0,
      weight: 0,
      length: 0,
      width: 0,
      height: 0,
      inventory: -1,
    });

    const addFinish = function () {
      if (!editablePrint.value.finishes) {
        editablePrint.value.finishes = [];
      }
      editablePrint.value.finishes.push('');
    }

    const removeFinish = function(index) {
      editablePrint.value.finishes.splice(index, 1);
    }

    const clearForm = function () {
      editablePrint.value = {
        format: props.formatGroup?.format || '',
        formatDescription: props.formatGroup?.formatDescription || '',
        name: '',
        size: '',
        finishes: ['Glossy', 'Matte'],
        unitPrice: 0,
        weight: 0,
        length: 0,
        width: 0,
        height: 0,
        inventory: -1,
      };
    };

    const saveChanges = async function () {
      try {
        isSaving.value = true;

        const newPrint = await store.dispatch('prints/createPrint', editablePrint.value);
        if (addingToFormat.value) {
          emit('created', newPrint);
        } else {
          await router.replace('/prints/search')
        }
      } catch (error) {
        console.error('error: ' + error);
        new Swal({
          title: 'Error!',
          text: 'We encountered a problem: ' + error.message,
          icon: 'error'
        });
      } finally {
        isSaving.value = false;
      }
    };

    return {
      isSaving,
      addingToFormat,
      editablePrint,

      addFinish,
      removeFinish,
      clearForm,
      saveChanges,

    }
  },
  emits: ['created'],
}
</script>

<style scoped>
.form-control {
  resize: vertical;
  min-height: 60px;
  width: 100%;
  max-width: 100%;
}

.d-flex {
  width: 100%;
}

/* Ensure the content area takes full width */
.flex-grow-1 {
  min-width: 0; /* Prevents flex item from overflowing */
}

.col-form-label {
  padding-top: 0;
  padding-bottom: 0;
  white-space: nowrap;
  text-align: end;
  font-weight: bolder;
}
</style>