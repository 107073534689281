<template>

  <loading-modal v-if="isSaving" title="Saving..."/>

  <div class="result-edit">
    <div class="row w-100">
      <div class="d-flex w-100">
        <div class="flex-grow-1">
          <div class="row align-items-center mb-2">
            <div class="col-2 text-end fw-bolder">
              <label for="formatName" class="form-label mb-0">Format:</label>
            </div>
            <div class="col-10">
              <input
                  id="formatName"
                  v-model="editableGroup.format"
                  class="form-control"
                  type="text"
                  placeholder="Enter Format Name"
                  required
              />
            </div>
          </div>
          <div class="row align-items-start mb-2">
            <div class="col-2 text-end fw-bolder">
              <label for="formatDescription" class="form-label">Description:</label>
            </div>
            <div class="col-10">
                <textarea
                    id="formatDescription"
                    v-model="editableGroup.formatDescription"
                    class="form-control"
                    rows="10"
                    placeholder="Format Description"
                ></textarea>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="column w-100 mb-4">
      <button class="btn btn-outline-primary" @click.prevent="saveGroupEdit">Save</button>
    </div>
  </div>
</template>
<script>
import {computed, ref} from "vue";
import LoadingModal from "@/components/LoadingModal.vue";
import {useStore} from "vuex";

export default {
  components: {LoadingModal},
  props: {
    formatGroup: {
      type: Object,
      required: true
    }
  },
  setup(props, {emit}) {

    const store = useStore();

    const isSaving = ref(false);

    const editableGroup = computed(() => {
      return {
        format: props.formatGroup.format,
        formatDescription: props.formatGroup.formatDescription,
      }
    })

    const saveGroupEdit = async () => {
      try {
        isSaving.value = true

        await store.dispatch('prints/updateFormatGroup', {
          ...editableGroup.value,
          prints: props.formatGroup.prints
        });

        const updatedGroup = await store.dispatch('prints/loadFormatGroup', {format: editableGroup.value.format});
        await store.dispatch('search/updateSearchResults', updatedGroup)

        emit('editCompleted')
      } finally {
        isSaving.value = false
      }
    }

    return {
      isSaving,
      editableGroup,
      saveGroupEdit,
    }
  },
  emits: ['editCompleted']
}
</script>