<template>

  <loading-modal v-if="isLoading" title="Searching..."/>

  <div class="container">
    <h2>Product Search Results</h2>

    <div class="mb-3">
      <router-link :to="{name: 'products-search'}" class="btn btn-outline-secondary">
        <i class="fas fa-arrow-left"></i> Back to Search
      </router-link>
    </div>

    <div v-if="results">
      <table class="table">
        <thead>
        <tr>
          <th @click="toggleSort('type')" style="width: 50px; cursor: pointer" class="text-start">
            Type
            <span v-if="getSortDirection('type')" class="sort-indicator">
              {{ getSortIndex('type') }}
              <i :class="['fas',getSortDirection('type') === 'asc' ? 'fa-sort-up' : 'fa-sort-down']"></i>
              </span>
          </th>
          <th @click="toggleSort('group')" style="cursor: pointer" class="text-start">
            Group
            <span v-if="getSortDirection('group')" class="sort-indicator">
                {{ getSortIndex('group') }}
                <i :class="['fas',getSortDirection('group') === 'asc' ? 'fa-sort-up' : 'fa-sort-down']"></i>
              </span>
          </th>
          <th class="text-center" style="width: 100px;">
            <button @click="clearSorting" class="btn btn-sm btn-outline-secondary">Clear Sort</button>
          </th>
        </tr>
        </thead>
        <tbody>
        <template v-for="(result, index) in results" :key="index">
          <tr>
            <td class="text-start">{{ result.type }}</td>
            <td class="text-start">{{ result.group }}</td>
            <td class="text-center">
              <button @click="toggleExpand(result.group)"
                      class="btn btn-sm"
                      :class="{'btn-primary': !isExpanded(result.group), 'btn-secondary': isExpanded(result.group)}">
                {{ isExpanded(result.group) ? 'Hide' : 'Show' }}
              </button>
            </td>
          </tr>
          <tr v-if="isExpanded(result.group)">
            <td colspan="3">
              <product-group-detail :group-type="result.type" :group-name="result.group" @updated="groupUpdated"/>
            </td>
          </tr>
          <tr v-for="(product, index) in result.products" :key="index">
            <td colspan="3" v-if="isExpanded(result.group)" class="border border-1">
              <product-detail :productId="product.productId" :select-name="result.selectName" @product-deleted="productDeleted" @updated="productUpdated"/>
            </td>
          </tr>
        </template>
        </tbody>
      </table>
    </div>
    <div v-else>
      No results found
    </div>
  </div>

  <nav aria-label="Page navigation">
    <ul class="pagination justify-content-center">
      <!-- Previous button -->
      <li class="page-item" :class="{ disabled: page === 1 }">
        <a class="page-link" href="#" @click="gotoPage(page - 1)" aria-label="Previous">
          <span aria-hidden="true">&laquo;</span>
        </a>
      </li>
      <!-- Page numbers -->
      <li v-for="pageNum in displayedPages"
          :key="pageNum"
          class="page-item"
          :class="{ active: pageNum === page }">
        <a class="page-link"
           href="#"
           @click="gotoPage(pageNum)">{{ pageNum }}</a>
      </li>
      <!-- Next button -->
      <li class="page-item" :class="{ disabled: page === pages }">
        <a class="page-link" href="#" @click="gotoPage(page + 1)" aria-label="Next">
          <span aria-hidden="true">&raquo;</span>
        </a>
      </li>
    </ul>
  </nav>
</template>

<script>
import { useStore } from 'vuex'
import {computed, onBeforeMount, ref} from 'vue'
import LoadingModal from "@/components/LoadingModal.vue";
import ProductDetail from "@/components/products/ProductItemDetail.vue";
import ProductGroupDetail from "@/components/products/ProductGroupDetail.vue";

export default {
  components: {
    ProductGroupDetail,
    ProductDetail,
    LoadingModal
  },
  setup() {

    const store = useStore()

    const isLoading = ref(false)
    const sortFields = ref([
      /*{field: 'created', order: 'desc'}*/
    ])

    const results = ref([]);
    const page = ref(1);
    const pages = ref(1);
    const startPage = ref(1);
    const endPage = ref(1);
    const displayedPages = computed(() => {
      const pages = [];
      for (let i = startPage.value; i <= endPage.value; i++) {
        pages.push(i);
      }
      return pages;
    });

    onBeforeMount(async () => {
      const searchResults = await store.getters['products/searchResults'];
      if (searchResults) {
        results.value = searchResults.pages[page.value];
        pages.value = searchResults.totalPages;
        startPage.value = 1;
        endPage.value = searchResults.totalPages;

        sortResults();
      } else {
        results.value = [];
        pages.value = 1;
        startPage.value = 1;
        endPage.value = 1;
      }
    });

    const expanded = ref([])
    // we should include type as well
    const toggleExpand = async function(group) {
      if (isExpanded(group)) {
        expanded.value = expanded.value.filter((e) => {
          return e !== group
        })
      } else {
        expanded.value.push(group)
      }
    }
    const isExpanded = function(group) {
      return expanded.value.find((e) => {
        return e === group
      })
    }

    const expandedProducts = ref([])
    const toggleExpandProduct = function(productId) {
      if (isProductExpanded(productId)) {
        expandedProducts.value = expandedProducts.value.filter((p) => {
          return p !== productId
        })
      } else {
        expandedProducts.value.push(productId)
      }
    }

    const isProductExpanded = function(productId) {
      return expandedProducts.value.find((e) => {
        return e === productId
      })
    }

    const toggleSort = function(field) {
      const existingSort = sortFields.value.find((s) => s.field === field)
      if (existingSort) {
        existingSort.order = existingSort.order === 'asc' ? 'desc' : 'asc'
      } else {
        sortFields.value.push({
          field: field,
          order: 'asc'
        })
      }

      sortResults();
    }

    const sortResults = function () {
      results.value.sort((a, b) => {
        for (const sort of sortFields.value) {
          // Handle non-date fields
          if (a[sort.field] !== b[sort.field]) {
            return sort.order === 'asc'
                ? a[sort.field] > b[sort.field] ? 1 : -1
                : a[sort.field] < b[sort.field] ? 1 : -1;
          }
        }
        return 0;
      });
    }

    const getSortIndex = function (field) {
      const index = sortFields.value.findIndex((s) => s.field === field)
      return index !== -1 ? index + 1 : null;
    }

    const getSortDirection = function (field) {
      const sort = sortFields.value.find((s) => s.field === field)
      return sort ? sort.order : null;
    }

    const clearSorting = function () {
      sortFields.value = [/*{
        field: 'group',
        order: 'asc'
      }*/]
      sortResults()
    }

    const gotoPage = async function (pageNumber) {
      try {
        isLoading.value = true
        await store.dispatch('products/searchProducts', {
          page: pageNumber
        });

        // load results from store
        const searchResults = await store.getters['products/searchResults'];

        page.value = pageNumber
        results.value = searchResults.pages[page.value];
        // look to see if we have this page
        //
      } finally {
        isLoading.value = false
      }
    }

    const productDeleted = async function (product) {

      await toggleExpand(product.group)

      // remove product from results
      results.value.forEach((result) => {
        result.products = result.products.filter((p) => {
          return p.productId !== product.productId
        })

        // if group.products empty, remove group from results
        if (result.products.length === 0) {
          results.value = results.value.filter((r) => {
            return r.group !== result.group
          })
        }
      })
    }

    const groupUpdated = async function (payload) {
      const {type, oldGroupName, newGroupName} = payload

      // if we changed the groupName - this will not work
      if (oldGroupName === newGroupName) {
        const newProductGroup = await store.dispatch('products/loadProductGroup', {
          type: type,
          group: newGroupName
        });
        const index = results.value.findIndex(result => result.group === newGroupName);
        if (index !== -1) {
          // Directly update the group at the found index
          results.value[index] = newProductGroup;
        }
      } else {
        // oldProductGroup can be null
        const oldProductGroup = await store.dispatch('products/loadProductGroup', {
          type: type,
          group: oldGroupName
        });
        const oldIndex = results.value.findIndex(result => result.group === oldGroupName);

        if (oldProductGroup == null) {
          // remove from results
          results.value = results.value.filter(result => result.group !== oldGroupName);
        } else if (oldIndex !== -1) {
          // Directly update the group at the found index
          results.value[oldIndex] = oldProductGroup;
        } else {
          console.error('Did not find oldGroupName: ' + oldGroupName)
        }

        const newProductGroup = await store.dispatch('products/loadProductGroup', {
          type: type,
          group: newGroupName
        });
        const newIndex = results.value.findIndex(result => result.group === newGroupName);
        if (newProductGroup != null) {
          if (newIndex !== -1) {
            // Directly update the group at the found index
            results.value[newIndex] = newProductGroup;
          } else {
            results.value.push(newProductGroup);
          }
        } else {
          console.error('Did not find newGroup: ' + newProductGroup)
        }
      }

      await toggleExpand(oldGroupName)
    }

    const productUpdated = async function (productId) {
      // find productGroup containing productId
      const productGroup = results.value.find((result) => {
        return result.products.find((product) => {
          return product.productId === productId
        })
      })

      if (productGroup) {
        toggleExpand(productGroup.group)
      }

      // reload productGroup
      const updatedProductGroup = await store.dispatch('products/loadProductGroup', {
        type: productGroup.type,
        group: productGroup.group
      });

      const index = results.value.findIndex(result => result.group === productGroup.group);
      if (index !== -1) {
        // Directly update the group at the found index
        results.value[index] = updatedProductGroup;
      }

    }

    return {
      isLoading,
      toggleExpand,
      isExpanded,
      toggleSort,
      getSortIndex,
      getSortDirection,
      clearSorting,

      results,
      pages,
      page,
      gotoPage,
      displayedPages,

      toggleExpandProduct,
      isProductExpanded,

      groupUpdated,
      productDeleted,
      productUpdated,
    }
  }
}
</script>