<template>
  <div class="search-result">
    <div class="result-container">
      <div class="details">
        <div v-if="expanded" class="expanded">
          <div v-if="isEditingGroup">
            <search-result-edit :format-group="formatGroup"
                                @editCompleted="editGroupCompleted"/>
          </div>
          <div v-else>
            <search-result-view :format-group="formatGroup"/>
          </div>
        </div>
        <div v-else class="minimized">
          <p class="text-start">{{formatGroup.format}}</p>
        </div>
        <div class="table">
          <div v-if="isAddingPrint">
            <search-result-detail-add :format-group="formatGroup"
                                      @addComplete="addPrintCompleted"/>
          </div>
          <div v-for="printResult in formatGroup.prints" :key="printResult.printId">
            <search-result-detail :print-result="printResult"
                                  :can-expand="!isEditingGroup"
                                  :can-edit="canEditPrint"
                                  @startEditing="startPrintEditing"
                                  @editCompleted="cancelPrintEditing"/>
          </div>
        </div>
      </div>
      <div class="toggle">
        <button class="btn btn-outline-secondary"
                @click="toggleDisplay"
                :disabled="isEditingPrint || isAddingPrint || isEditingGroup">
          {{expanded ? 'Collapse' : 'Expand'}}
        </button>
        <button v-if="expanded" class="btn"
                @click="toggleEditGroup"
                :class="{'btn-outline-danger' : isEditingGroup, 'btn-outline-secondary': !isEditingGroup}"
                :disabled="isAddingPrint || isEditingPrint">
          {{ isEditingGroup ? 'Cancel Edit' : 'Edit Group'}}
        </button>
        <button v-if="expanded" class="btn"
                @click="toggleAddPrint"
                :class="{'btn-outline-danger' : isAddingPrint, 'btn-outline-secondary': !isAddingPrint}"
                :disabled="isEditingPrint || isEditingGroup">
          {{ isAddingPrint ? 'Cancel Add' : 'Add Print'}}
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import {computed, ref} from "vue";
import SearchResultEdit from "@/components/search/SearchResultEdit.vue";
import SearchResultView from "@/components/search/SearchResultView.vue";
import SearchResultDetail from "@/components/search/SearchResultDetail.vue";
import SearchResultDetailAdd from "@/components/search/SearchResultDetailAdd.vue";

export default {
  components: {
    SearchResultDetailAdd,
    SearchResultDetail,
    SearchResultView,
    SearchResultEdit,
  },
  props: {
    formatGroup: {
      type: Object,
      required: true
    }
  },
  setup() {

    const expanded = ref(false);
    const isEditingGroup = ref(false)
    const isEditingPrint = ref(false);
    const isAddingPrint = ref(false);

    const toggleDisplay = () => {
      expanded.value = !expanded.value
      if (!expanded.value) {
        isEditingGroup.value = false
      }
    }

    const toggleAddPrint = () => {
      if (isEditingGroup.value || isEditingPrint.value) {
        return;
      }

      isAddingPrint.value = !isAddingPrint.value
    }

    const toggleEditGroup = () => {
      if (isAddingPrint.value || isEditingPrint.value) {
        return;
      }

      isEditingGroup.value = !isEditingGroup.value
    }


    const startPrintEditing = () => {
      expanded.value = true;
      isEditingPrint.value = true;
    }

    const cancelPrintEditing = () => {
      isEditingPrint.value = false;
    }

    const canEditPrint = computed(() => {
      return !isEditingGroup.value && !isAddingPrint.value
    });

    const addPrintCompleted = () => {
      isAddingPrint.value = false;
    }

    const editGroupCompleted = () => {
      isEditingGroup.value = false;
    }

    return {
      expanded,
      isEditingGroup,
      isEditingPrint,
      isAddingPrint,
      canEditPrint,

      toggleDisplay,
      toggleAddPrint,
      toggleEditGroup,

      startPrintEditing,
      cancelPrintEditing,
      addPrintCompleted,

      editGroupCompleted
    }
  }
}
</script>
<style scoped>
.search-result {
  width: 100%;
}

.result-container {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.details {
  flex: 0 0 90%; /* Takes up 90% of the container width */
}

.toggle {
  flex: 0 0 10%; /* Takes up 10% of the container width */
  display: flex;
  flex-direction: column; /* Stack buttons vertically */
  gap: 0.5rem; /* Add space between buttons */
  align-items: flex-end; /* Align buttons to the right */
}

.toggle button {
  white-space: nowrap;
  width: 100px;
}
</style>