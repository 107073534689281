import axios from "axios";

export const orders =  {
    namespaced: true,
    state: {
        searchResults: null,
        searchParams: {},
        orderDetails: []
    },
    getters: {
        searchResults: (state) => state.searchResults,
        searchParams: (state) => state.searchParams,
        orderDetails: (state) => state.orderDetails
    },
    mutations: {
        setSearchResults(state, searchResults) {
            state.searchResults = searchResults
        },
        setSearchParams(state, searchParams) {
            state.searchParams = searchParams
        },
        setOrderDetails(state, orderDetails) {
            state.orderDetails = orderDetails
        }
    },
    actions: {
        async clearSearch(context) {
            context.commit('setSearchParams', {})
            context.commit('setSearchResults', null)
            context.commit('setOrderDetails', [])
        },
        async loadOrderDetail(context, orderId) {
            // do query
            const host = 'api.mossbeachlife.com';
            const path = `/admin/orders/${orderId}`;

            const getRequest = {
                method: 'GET',
                hostname: host,
                path: path,
                headers: {
                    host: host,
                },
                // Add query parameters to the request object
                // query: queryParams
            }
            const signedRequest = await context.dispatch('auth/signRequest', getRequest, {root: true});

            const headers = signedRequest.headers;
            delete headers['host'];

            const url = process.env.VUE_APP_API_URL + path;
            const response = await axios.get(url, {
                headers: headers
            });
            return response.data;
        },
        async searchOrders(context, payload) {
            /**
             * query params can contain either a list of filters, or a page number
             * if a list of filters, we store that locally for use if we request a different page
             */
            if (payload.page) {
                // by including a page, we assume we have already done an initial search
                // check to see if we have already loaded results
                if (context.getters.searchResults) {
                    const searchResults = context.getters.searchResults
                    if (searchResults.pages) {
                        const pages = searchResults.pages;
                        const page = payload.page.toString();
                        if(pages[page]) {
                            return pages[page];
                        }
                    }
                }
            }

            const queryParams = payload.filters || context.getters.searchParams || {};
            await context.commit('setSearchParams', queryParams)

            // do not store page as part of locally stored searchParams
            if (payload.page) {
                queryParams.page = payload.page.toString();
            }

            // do query
            const host = 'api.mossbeachlife.com';
            const path = '/admin/orders';

            let pathWithQuery = path
            if (queryParams) {
                const queryString = new URLSearchParams(queryParams).toString();
                if (queryString) {
                    pathWithQuery = `${path}?${queryString}`;
                }
            }

            const getRequest = {
                method: 'GET',
                hostname: host,
                path: path,
                headers: {
                    host: host,
                },
                // Add query parameters to the request object
                query: queryParams
            }

            const signedRequest = await context.dispatch('auth/signRequest', getRequest, {root: true});
            const headers = signedRequest.headers;
            delete headers['host'];

            const url = process.env.VUE_APP_API_URL + pathWithQuery;
            const response = await axios.get(url, {
                headers: headers
            });

            const pagination = response.data.pagination;
            const currentPage = pagination.currentPage;
            const totalPages = pagination.totalPages;

            const items = response.data.items;

            //  if searchResults already present then append new results to it
            const searchResults = context.getters.searchResults;
            if (searchResults) {
                searchResults.pages[currentPage] = items;
                await context.commit('setSearchResults', searchResults);
            } else {
                const results = {
                    totalPages: totalPages,
                    pages: {
                        // is this key a string?
                        [currentPage]: items
                    }
                }
                await context.commit('setSearchResults', results);
            }

            return items;
        },
    }
}