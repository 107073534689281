const config = {
    region: "us-west-1",
    awsAccountId: "089600871681",
    clientId: "7ecndu5m3la4d9f4v6678pi0jd",
    identityPoolId: "us-west-1:f0b87b1a-8ec5-4c63-a8b1-26b3df737de1",
    // userPoolId: "us-west-1:a32a5ad6-c653-4f37-9b78-bb3a30e84d74",
    userPoolRegion: "us-west-1",
    userPoolId: "us-west-1_PFTSpufKl",
    // login: `cognito-idp.us-west-1.amazonaws.com/us-west-1_PFTSpufKl`,
}

export { config }