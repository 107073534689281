import {
    CognitoIdentityProviderClient,
    InitiateAuthCommand,
    UserNotConfirmedException,
    RespondToAuthChallengeCommand,
    GlobalSignOutCommand,
} from "@aws-sdk/client-cognito-identity-provider";
import {
    CognitoIdentityClient,
    GetCredentialsForIdentityCommand,
    GetIdCommand
} from "@aws-sdk/client-cognito-identity";

import { config } from '@/config';
import {SignatureV4} from "@aws-sdk/signature-v4";
import {Sha256} from "@aws-crypto/sha256-browser";

const cognitoUserPoolClient = new CognitoIdentityProviderClient({region: config.region});
const cognitoIdentityPoolClient = new CognitoIdentityClient({region: config.region});

function getUserPoolUrl() {
    const userPoolRegion = config.userPoolRegion;
    const userPoolId = config.userPoolId;
    const userPoolUrl = `cognito-idp.${userPoolRegion}.amazonaws.com/${userPoolId}`
    return userPoolUrl;
}

export const auth = {
    namespaced: true,
    state: {
        // user: null,
        // identityId: null,
        authorizationTokens: null,
        username: null
    },
    getters: {
        getAuthorizationTokens(state) {
            return state.authorizationTokens;
        },
        isAuthenticated(state) {
            const authenticated = state.authorizationTokens != null;
            // todo: verify not timed out
            return authenticated;
        },
        username(state) {
            return state.username
        }
    },
    mutations: {
        setAuthorizationTokens(state, payload) {
            state.authorizationTokens = payload;
        },
    },
    actions: {
        async login(context, payload) {
            try {
                const result = await context.dispatch('initiateAuth', payload);
                if ('authenticated' === result.code) {
                    // const auth_tokens = await context.getters.getAuthorizationTokens;
                    // await context.dispatch('_fetchCurrentUser', auth_tokens.AccessToken)
                    return {
                        result: 'authenticated'
                    }
                } else if ('challenge' === result.code) {
                    return {
                        result: 'new_password',
                        session: result.session
                    }
                }
            } catch (error) {
                if (error instanceof UserNotConfirmedException) {
                    return {
                        result: 'confirmIdentity'
                    };
                } else {
                    console.error('login error: ' + JSON.stringify(error, null, 2));
                    throw error;
                }
            }
        },
        async logout(context) {
            try {
                // API https://docs.aws.amazon.com/cognito-user-identity-pools/latest/APIReference/API_GlobalSignOut.html
                // javascript https://docs.aws.amazon.com/AWSJavaScriptSDK/v3/latest/client/cognito-identity-provider/command/GlobalSignOutCommand/
                const authTokens = await context.getters.getAuthorizationTokens
                if (authTokens != null && authTokens.AccessToken != null) {
                    await cognitoUserPoolClient.send(new GlobalSignOutCommand({
                        AccessToken: authTokens.AccessToken
                    }));
                }

            } catch (error) {
                console.error('logout error: ' + JSON.stringify(error, null, 2));
            }

            await context.dispatch("_clearCredentials");
        },

        async initiateAuth(context, payload) {
            try {
                // API https://docs.aws.amazon.com/cognito-user-identity-pools/latest/APIReference/API_InitiateAuth.html
                // javascript https://docs.aws.amazon.com/AWSJavaScriptSDK/v3/latest/client/cognito-identity-provider/command/InitiateAuthCommand/
                const response = await cognitoUserPoolClient.send(new InitiateAuthCommand({
                    ClientId: config.clientId,
                    AuthFlow: 'USER_PASSWORD_AUTH',
                    AuthParameters: {
                        USERNAME: payload.username,
                        PASSWORD: payload.password,
                    }
                }));

                const challenge = response.ChallengeName;
                if (challenge) {
                    //   ChallengeName: "SMS_MFA" || "SOFTWARE_TOKEN_MFA" || "SELECT_MFA_TYPE" || "MFA_SETUP" || "PASSWORD_VERIFIER" || "CUSTOM_CHALLENGE" || "DEVICE_SRP_AUTH" || "DEVICE_PASSWORD_VERIFIER" || "ADMIN_NO_SRP_AUTH" || "NEW_PASSWORD_REQUIRED",
                    if ('NEW_PASSWORD_REQUIRED' === challenge) {
                        return  {
                            code: 'challenge',
                            challenge: 'new_password',
                            session: response.Session
                        };
                    } else {
                        throw new Error('Unsupported challenge ' + challenge);
                    }

                    // All of the following challenges require USERNAME and SECRET_HASH (if applicable) in the parameters.
                    // SMS_MFA, PASSWORD_VERIFIER, CUSTOM_CHALLENGE, DEVICE_SRP_AUTH, DEVICE_PASSWORD_VERIFIER, NEW_PASSWORD_REQUIRED, MFA_SETUP

                    // NEW_PASSWORD_REQUIRED
                    /*
                    Respond to this challenge with NEW_PASSWORD and any required attributes that Amazon Cognito returned in the requiredAttributes parameter. You can also set values for attributes that aren't required by your user pool and that your app client can write. For more information, see RespondToAuthChallenge.
                    In a NEW_PASSWORD_REQUIRED challenge response, you can't modify a required attribute that already has a value. In RespondToAuthChallenge, set a value for any keys that Amazon Cognito returned in the requiredAttributes parameter, then use the UpdateUserAttributes API operation to modify the value of any additional attributes.
                     */
                }

                const now = Date.now()
                const expiresIn = response.AuthenticationResult.ExpiresIn;
                const tokenExpiresTimestamp = (expiresIn*1000) + now;
                const auth_tokens = {
                    TokenExpires: tokenExpiresTimestamp,
                    AccessToken: response.AuthenticationResult.AccessToken,
                    IdToken: response.AuthenticationResult.IdToken,
                    RefreshToken: response.AuthenticationResult.RefreshToken
                };



                // set storage
                localStorage.setItem('auth_tokens', JSON.stringify(auth_tokens));
                await context.commit("setAuthorizationTokens", auth_tokens);
                return {
                    code: 'authenticated'
                };
            } catch (error) {
                console.error('initiateAuth error: ' + JSON.stringify(error, null, 2));
                throw error;
            }
        },
        async _clearCredentials(context) {
            await context.commit("setAuthorizationTokens", null);
            localStorage.removeItem('auth_tokens');
        },
        async newPasswordChallenge(context, payload) {
            try {
                // response is same as initiateAuth
                const response = await cognitoUserPoolClient.send(new RespondToAuthChallengeCommand({
                    ClientId: config.clientId,
                    ChallengeName: "NEW_PASSWORD_REQUIRED",
                    ChallengeResponses: {
                        "NEW_PASSWORD": payload.newPassword,
                        "USERNAME": payload.username
                    },
                    Session: payload.session
                }));

                const challenge = response.ChallengeName;
                if (challenge) {
                    throw new Error('Nested Challenge not supported');
                } else {
                    // this is same as in initAuth
                    const now = Date.now()
                    const expiresIn = response.AuthenticationResult.ExpiresIn;
                    const tokenExpiresTimestamp = (expiresIn*1000) + now;
                    const auth_tokens = {
                        TokenExpires: tokenExpiresTimestamp,
                        AccessToken: response.AuthenticationResult.AccessToken,

                        // TODO: these stay the same - remove from AuthTokens
                        IdToken: response.AuthenticationResult.IdToken,
                        RefreshToken: response.AuthenticationResult.RefreshToken
                    };

                    // set storage
                    localStorage.setItem('auth_tokens', JSON.stringify(auth_tokens));
                    await context.commit("setAuthorizationTokens", auth_tokens);

                    return {
                        code: 'authenticated'
                    };
                }
            } catch (error) {
                console.error('newPasswordChallenge error: ' + JSON.stringify(error, null, 2));
                throw error;
            }
        },

        async getCredentials(context) {
            const federatedIdentityId = await context.dispatch('fetchFederatedIdentityId');

            try {
                const userPoolUrl = getUserPoolUrl();
                const idToken = await context.getters.getAuthorizationTokens.IdToken;
                const logins = {
                    // who logged us in? This is the app client url
                    [userPoolUrl]: idToken
                };

                const getCredentialsForIdentityCommand = new GetCredentialsForIdentityCommand({
                    IdentityId: federatedIdentityId,
                    Logins: logins
                })
                const credentialsResponse = await cognitoIdentityPoolClient.send(getCredentialsForIdentityCommand);
                return credentialsResponse['Credentials'];
            } catch (error) {
                console.error('fetchAWSCredentials error: ' + JSON.stringify(error, null, 2));
                // TODO: if auth issue, try to refresh
                /*if (error.name === 'NotAuthorizedException') {
                    const authTokens = await context.getters.getAuthorizationTokens;
                    await context.dispatch('refreshAuth', authTokens);

                    // recurse
                    return await context.dispatch('fetchAWSCredentials')
                }*/

                await context.dispatch('_clearCredentials');
                throw error
            }
        },
        async fetchFederatedIdentityId(context) {
            try {
                const userPoolUrl = getUserPoolUrl();
                const idToken = await context.getters.getAuthorizationTokens.IdToken;
                const logins = {
                    // who logged us in? This is the app client url
                    [userPoolUrl]: idToken
                };

                const getIdCommand = new GetIdCommand({
                    IdentityPoolId: config.identityPoolId,
                    AccountId: config.awsAccountId,
                    Logins: logins
                })

                const getIdResponse = await cognitoIdentityPoolClient.send(getIdCommand);
                return getIdResponse.IdentityId;
            } catch (error) {
                console.error('fetchFederatedIdentityId error: ' + JSON.stringify(error, null, 2));
                // TODO: if auth issue, try to refresh
                /*if (error.name === 'NotAuthorizedException') {
                    const authTokens = await context.getters.getAuthorizationTokens;
                    await context.dispatch('refreshAuth', authTokens);

                    // recurse
                    return await context.dispatch('fetchFederatedIdentityId')
                }*/

                await context.dispatch('_clearCredentials');
                throw error;
            }
        },

        async getSigner(context, credentials) {

            return new SignatureV4({
                credentials: {
                    accessKeyId: credentials.AccessKeyId,
                    secretAccessKey: credentials.SecretKey,
                    sessionToken: credentials.SessionToken
                },
                region: 'us-west-1',
                service: 'execute-api',
                sha256: Sha256
            });
        },

        async getPOSTRequest(context, payload) {
            const host = payload.host
            const path = payload.path
            const contentType = payload.contentType || 'application/json'
            const body = payload.body

            return {
                method: 'POST',
                hostname: host,
                path: path,
                headers: {
                    'Content-Type': contentType,
                    host: host,
                },
                body: JSON.stringify(body)
            }
        },

        async getPUTRequest(context, payload) {
            const host = payload.host
            const path = payload.path
            const contentType = payload.contentType || 'application/json'
            const body = payload.body

            return {
                method: 'PUT',
                hostname: host,
                path: path,
                headers: {
                    'Content-Type': contentType,
                    host: host,
                },
                body: JSON.stringify(body)
            }
        },

        async signRequest(context, request) {
            const credentials = await context.dispatch('getCredentials');
            const signer = await context.dispatch('getSigner', credentials)
            return await signer.sign(request)
        }
    },
}