<template>
  <loading-modal v-if="isLoading" title="Loading..."/>

  <div class="card my-2 border-light">
    <div class="card-header d-flex justify-content-between align-items-center">
      <button @click="toggleShippingLabel()"
              class="btn btn-sm btn-success me-1">
        Shipping Label
      </button>

      <button @click="togglePackingSlip()"
              class="btn btn-sm btn-success ms-1">
        Packing Slip
      </button>
    </div>
    <div class="card-body p-2">
      <div class="d-flex flex-wrap gap-2">
        <!-- User Details -->
        <div class="flex-grow-1 text-start">
          <div class="mb-1">
            <small class="text-muted">Email:</small>
            <span class="ms-1">
              <router-link :to="{name: 'email'}">{{username}}</router-link>
            </span>
          </div>
          <div v-if="orderDetail.promoCode" class="mb-1">
            <small class="text-muted">Promo:</small>
            <span class="ms-1">
              {{ orderDetail.promoCode}}
            </span>
          </div>
          <div v-if="orderDetail.specialInstructions" class="mb-1">
            <small class="text-muted">Instructions:</small>
            <span class="ms-1">
              {{ orderDetail.specialInstructions}}
            </span>
          </div>
        </div>

        <!-- Shipping -->
        <div class="flex-grow-1 text-start">
          <div class="mb-1">
            <small class="text-muted">Ship By:</small>
            <span class="ms-1">
              {{shipMethod}}
            </span>
          </div>
          <div class="mb-1">
            <small class="text-muted">Ship To:</small>
            <span class="ms-1">
              {{shipToName}}
            </span>
          </div>
          <div class="mb-1">
            <small class="text-muted">Address:</small>
          </div>
          <div class="mb-1">
            <span class="ms-1">
              {{shipToAddress}}
            </span>
          </div>
          <div class="mb-1">
            <span class="ms-1">
              {{shipToCity}}, {{shipToState}} {{shipToPostalCode}}
            </span>
          </div>
          <div class="mb-1">
            <span class="ms-1">
              {{shipToCountry}}
            </span>
          </div>
        </div>

        <!-- Payment -->
        <div class="flex-grow-1 text-start">
          <div class="mb-1">
            <small class="text-muted">Subtotal:</small>
            <span class="ms-1">
              {{subtotal}}
            </span>
          </div>
          <div class="mb-1">
            <small class="text-muted">shipping:</small>
            <span class="ms-1">
              {{shippingCharge}}
            </span>
          </div>
          <div class="mb-1">
            <small class="text-muted">Subtotal:</small>
            <span class="ms-1">
              {{totalCharge}}
            </span>
          </div>
          <div class="mb-1">
            <small class="text-muted">Receipt:</small>
            <span class="ms-1">
              <a v-if="receiptUrl"
                 :href="receiptUrl"
                 target="_blank"
                 class="btn btn-sm btn-link py-0">
                {{ stripeChargeId }}
              </a>
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="modal fade" id="shippingLabelModal" tabindex="-1" aria-labelledby="shippingLabelModalLabel" aria-hidden="true">
    <div class="modal-dialog  modal-dialog-centered">
      <ShippingLabel v-if="showShippingLabel" :order="orderDetail" />
    </div>
  </div>

    <div class="modal fade" id="packingSlipModal" tabindex="-1" aria-labelledby="packingSlipModalLabel" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered">
        <packing-slip v-if="showPackingSlip" :order="orderDetail" />
      </div>
    </div>
</template>
<script>
import {onBeforeMount, onMounted, ref} from "vue";
import {useStore} from "vuex";
import { Modal } from 'bootstrap';
import LoadingModal from "@/components/LoadingModal.vue";
import ShippingLabel from "@/components/orders/ShippingLabel.vue";
import PackingSlip from "@/components/orders/PackingSlip.vue";

function formatCurrency(amount) {
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2,
  });

  return formatter.format(amount > 0 ? amount / 100 : 0.00);
}

export default {
  name: 'OrderDetail',
  components: {
    LoadingModal,
    ShippingLabel,
    PackingSlip,
  },
  props: {
    orderId: {
      type: String,
      required: true
    }
  },
  setup(props) {

    const store = useStore();

    const isLoading = ref(false);

    const orderDetail = ref({});

    const username = ref('');

    const shipMethod = ref('');
    const shipToName = ref('');
    const shipToAddress = ref('');
    const shipToCity = ref('');
    const shipToState = ref('');
    const shipToPostalCode = ref('');
    const shipToCountry = ref('');

    const subtotal = ref('');
    const shippingCharge = ref('');
    const totalCharge = ref('');
    const stripeChargeId = ref('');
    const receiptUrl = ref('');

    const showShippingLabel = ref(false);
    const showPackingSlip = ref(false);

    let shippingLabelModal = null;
    let packingSlipModal = null;

    onBeforeMount(async () => {
      try {
        isLoading.value = true;

        orderDetail.value = await store.dispatch('orders/loadOrderDetail', props.orderId)
        if (orderDetail.value.username) {
          username.value = orderDetail.value.username || ''
        }

        shipMethod.value = orderDetail.value.shipMethod || null;
        shipToName.value = orderDetail.value.shipToName || '';
        shipToAddress.value = orderDetail.value.shipToAddress || '';
        shipToCity.value = orderDetail.value.shipToCity || '';
        shipToState.value =orderDetail.value.shipToState || '';
        shipToPostalCode.value = orderDetail.value.shipToPostalCode || '';
        shipToCountry.value = orderDetail.value.shipToCountry || '';

        if (orderDetail.value.subtotal) {
          subtotal.value = formatCurrency(orderDetail.value.subtotal);
        }
        if (orderDetail.value.shippingCharge) {
          shippingCharge.value = formatCurrency(orderDetail.value.shippingCharge);
        }
        if (orderDetail.value.totalCharge) {
          totalCharge.value = formatCurrency(orderDetail.value.totalCharge);
        }

        stripeChargeId.value = orderDetail.value.stripeChargeId || null;
        receiptUrl.value = orderDetail.value.receiptUrl || null;

      } catch (e) {
        console.error(e);
      } finally {
        isLoading.value = false;
      }
    })

    onMounted(() => {
      shippingLabelModal = new Modal(document.getElementById('shippingLabelModal'))
      packingSlipModal = new Modal(document.getElementById('packingSlipModal'))
    })

    const toggleShippingLabel = async function() {
      showShippingLabel.value = !showShippingLabel.value
      if (showShippingLabel.value) {
        shippingLabelModal.show()
      } else {
        shippingLabelModal.hide()
      }
    }

    const togglePackingSlip = async function() {
      showPackingSlip.value = !showPackingSlip.value
      if (showPackingSlip.value) {
        packingSlipModal.show()
      } else {
        packingSlipModal.hide()
      }
    }

    return {
      isLoading,
      orderDetail,

      username,
      shipMethod,
      shipToName,
      shipToAddress,
      shipToCity,
      shipToState,
      shipToPostalCode,
      shipToCountry,

      subtotal,
      shippingCharge,
      totalCharge,
      stripeChargeId,
      receiptUrl,

      toggleShippingLabel,
      togglePackingSlip,
      showShippingLabel,
      showPackingSlip,
    }
  }
}
</script>
<style scoped>
.modal-dialog {
  max-width: 5in; /* Slightly larger than shipping label */
}
</style>