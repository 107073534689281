export const search =  {
    namespaced: true,
    state: {
        searchResults: null,
    },
    getters: {
        getSearchResults: (state) => state.searchResults,
    },
    mutations: {
        setSearchResults(state, searchResults) {
            state.searchResults = searchResults
        },

    },
    actions: {
        clearSearch(context) {
            context.commit('setSearchResults', null)
        },
        async updateSearchResults(context, searchResult) {
            // Get current search results from state
            let searchResults = [...context.state.searchResults]

            // For each print in the new searchResult
            searchResult.prints.forEach(newPrint => {
                // Update each format group in searchResults
                searchResults = searchResults.map(formatGroup => {
                    return {
                        ...formatGroup,
                        // Filter out any prints that match the current newPrint
                        prints: formatGroup.prints.filter(existingPrint =>
                            existingPrint.printId !== newPrint.printId
                        )
                    }
                })
            })

            // Remove any format groups that now have no prints
            searchResults = searchResults.filter(result => result.prints.length > 0)

            // Add the new searchResult to the searchResults array
            searchResults.push(searchResult)

            // Commit the updated results
            context.commit('setSearchResults', searchResults)
        },
        async removeSearchResultDetail(context, searchResultDetail) {
            const { printId } = searchResultDetail
            // Create a new array, removing the print with matching printId from any format group
            const updatedSearchResults = context.state.searchResults.map(searchResult => {
                return {
                    ...searchResult,
                    prints: searchResult.prints.filter(print => print.printId !== printId)
                }
            });

            // Remove any format groups that now have no prints
            const cleanedResults = updatedSearchResults.filter(result => result.prints.length > 0);

            // Commit the changes to state
            context.commit('setSearchResults', cleanedResults);
        },
        /**
         * we have modified the printResultDetail, so we need to replace it in the search results
         * @param context
         * @param searchResultDetail
         * @returns {Promise<void>}
         */
        async replaceSearchResultDetail(context, searchResultDetail) {

            /*
            Expected format
            "printDetail": is optional
            {
              "printId": "prod_RdvU1SwDTrOJ3R",
              "name": "TestFormat, 10x10",
              "size": "8x8",
              "created": 1737689561,
              "updated": 1737758596080,
              "printDetail": {...}
            }
            */

            // Map through format groups
            const updatedSearchResults = context.state.searchResults.map(formatGroup => {
                return {
                    ...formatGroup,
                    // Map through prints
                    prints: formatGroup.prints.map(print => {
                        // If print ID matches, replace with new searchResultDetail
                        if (print.printId === searchResultDetail.printId) {
                            return {
                                ...searchResultDetail
                            }
                        }
                        // Otherwise keep existing print
                        return print
                    })
                }
            })

            context.commit('setSearchResults', updatedSearchResults)
        },
        /**
         * We are adding printDetail to the search results to prevent unneccessary api calls
         * @param context
         * @param searchResultDetail
         * @returns {Promise<void>}
         */
        async updateSearchResultDetail(context, searchResultDetail) {
            /*
            Expected format
            {
              "printId": "prod_RdvU1SwDTrOJ3R",
              "name": "TestFormat, 10x10",
              "size": "8x8",
              "created": 1737689561,
              "updated": 1737758596080,
              "printDetail": {
                "printId": "prod_RdvU1SwDTrOJ3R",
                "format": "TestFormat",
                "formatDescription": "Test Description",
                "name": "TestFormat, 10x10",
                "size": "8x8",
                "finishes": [
                  "Glossy",
                  "Matte"
                ],
                "unitPrice": 3000,
                "inventory": -1,
                "created": 1737689561,
                "updated": 1737758596080,
                "priceId": "price_1QkvLgFuBPTXHmxdhjdooZJP",
                "height": 1,
                "length": 8,
                "width": 8,
                "weight": 25,
                "livemode": true,
                "active": true,
                "shippable": null,
                "stripeDescription": null,
                "statementDescriptor": null,
                "unitLabel": null,
                "stripeCreated": 1737689561,
                "stripeUpdated": 1737758595
              }
            }
             */


            // Create a new array, removing the print with matching printId from any format group
            const updatedSearchResults = context.state.searchResults.map(searchResult => {
                return {
                    ...searchResult,
                    prints: searchResult.prints.map(print => {
                        if (print.printId === searchResultDetail.printId) {
                            // Create a new print object with the updated detail
                            const updatedPrint = {
                                ...searchResultDetail
                            }
                            return updatedPrint
                        }
                        return print
                    })
                }
            });
            context.commit('setSearchResults', updatedSearchResults)
        },
        async addSearchResultDetail(context, searchResultDetail) {
            /*
            Expected format
            {
              "printId": "XXXXXXXXXXXXXXXXXXX",
              "name": "TestFormat, 10x10",
              "size": "8x8",
              "created": 1737689561,
              "updated": 1737758596080,
              "printDetail": {
                "printId": "XXXXXXXXXXXXXXXXXXX",
                "format": "TestFormat",
                "formatDescription": "Test Description",
                "name": "TestFormat, 10x10",
                "size": "8x8",
                "finishes": [
                  "Glossy",
                  "Matte"
                ],
                "unitPrice": 3000,
                "inventory": -1,
                "created": 1737689561,
                "updated": 1737758596080,
                "priceId": "XXXXXXXXXXXXXXXXXXXXXXXXXXXXXX",
                "height": 1,
                "length": 8,
                "width": 8,
                "weight": 25,
                "livemode": true,
                "active": true,
                "shippable": null,
                "stripeDescription": null,
                "statementDescriptor": null,
                "unitLabel": null,
                "stripeCreated": 1737689561,
                "stripeUpdated": 1737758595
              }
            }
             */

            // this may be a new format
            const updatedSearchResults = context.state.searchResults.map(formatGroup => {
                if (formatGroup.format === searchResultDetail.format) {
                //     add searchResultDetail to prints array
                    formatGroup.prints.push({
                        ...searchResultDetail
                    })
                    return formatGroup
                } else {
                    // create new format group with searchResultDetail as the only print
                    const newFormatGroup = {
                        format: searchResultDetail.format,
                        formatDescription: searchResultDetail.formatDescription,
                        created: searchResultDetail.created,
                        updated: searchResultDetail.updated,
                        prints: [searchResultDetail]
                    }
                    return newFormatGroup
                }
            })
            context.commit('setSearchResults', updatedSearchResults)
        },
    }
}