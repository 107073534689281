<template>
  <loading-modal v-if="isLoading" title="Searching..."/>

  <div class="container">
    <h2>Prints Search</h2>

    <div class="row">
      <div class="col-12">
        <div class="form-group">

          <form @submit.prevent="submitForm">
            <div class="mb-3 input-group">
              <span class="input-group-text">Format</span>
              <select class="form-select" v-model="format">
                <option value="">ALL</option>
                <option v-for="format in formats" :key="format" :value="format">{{ format }}</option>
              </select>
            </div>
            <div class="mb-3 input-group">
              <span class="input-group-text">Size</span>
              <input type="text" class="form-control" v-model.trim="size"/>
            </div>
            <div class="mb-3 input-group">
              <span class="input-group-text">Name</span>
              <input type="text" class="form-control" v-model.trim="name"/>
            </div>
            <div class="mb-3 d-flex justify-content-between">
              <input type="button" value="Clear" class="btn bg-danger text-white btn-block" :class="isLoading ? 'disabled' : ''" @click="clearForm">
              <input type="submit" value="Submit" class="btn btn-primary btn-block">
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Swal from 'sweetalert2'
import LoadingModal from "@/components/LoadingModal.vue";
import {
  onBeforeMount,
  ref
} from "vue";
import {useStore} from "vuex";
import {useRouter} from "vue-router";

export default {
  components: {LoadingModal},
  setup() {

    const store = useStore();
    const router = useRouter();

    const isLoading = ref(false)

    const formats = ref([]);
    const format = ref('');
    const size = ref('');
    const name = ref('');

    onBeforeMount(async () => {
      try {
        isLoading.value = true;
        await store.dispatch('prints/clearSearch');
        await store.dispatch('search/clearSearch');
        formats.value = await store.dispatch('prints/loadFormats');
      } catch (error) {
        console.error('Exception: ' + JSON.stringify(error))
        new Swal({
          title: 'Error!',
          text: 'We encountered a problem: ' + error.message,
          icon: 'error'
        });
      } finally {
        isLoading.value = false;
      }
    })

    const submitForm = async () => {
      try {
        const queryParams = {}
        if (format.value) queryParams.type = format.value;
        if (size.value) queryParams.group = size.value;
        if (name.value) queryParams.name = name.value;

        isLoading.value = true;

        const results = await store.dispatch('prints/searchPrints', {filters: queryParams});
        store.commit('search/setSearchResults', results);

        if (Object.keys(results).length > 0) {
          await router.push({
            name: 'prints-results',
          });
        } else {
          // show dialog no results found
          new Swal({
            title: 'No Results',
            text: 'No results found for your search criteria.',
            icon: 'info'
          });
        }
      } catch (error) {
        console.error('Exception: ' + JSON.stringify(error))
        new Swal({
          title: 'Error!',
          text: 'We encountered a problem: ' + error.message,
          icon: 'error'
        });
      } finally {
        isLoading.value = false;
      }
    }

    const clearForm = () => {
      format.value = ''
      size.value = ''
      name.value = ''
    }

    return {
      isLoading,
      formats,
      format,
      size,
      name,

      submitForm,
      clearForm,
    }
  },
}
</script>