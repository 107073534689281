<template>
  <div>
    <base-dialog :show="!!error" title="An error occurred" @close="handleError">
      <p>{{ error.message }}</p>
    </base-dialog>
    <base-dialog fixed :show="isLoading" title="Authenticating...">
      <base-spinner></base-spinner>
    </base-dialog>
    <h3>AWS Polly</h3>
    <div class="flex flex-col m-auto w-64">
      <div id="textToSynth">
        <textarea
            class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight"
            type="text"
            v-model="speech"
            id="speech"
            rows="5"
            autofocus
        />
        <button type="button" class="btn-blue-outline mt-2" @click="synthesize">Synthesize</button>
      </div>
      <div class="flex flex-col mt-10">
        <audio id="audioPlayback" controls>
          <source id="audioSource" type="audio/mp3" src="">
        </audio>
      </div>
    </div>
  </div>
</template>

<script>
// https://docs.aws.amazon.com/AWSJavaScriptSDK/v3/latest/Package/-aws-sdk-polly-request-presigner/
import { Polly } from "@aws-sdk/client-polly";
import { getSynthesizeSpeechUrl } from "@aws-sdk/polly-request-presigner";
import {config} from "@/config";
import BaseDialog from "@/components/BaseDialog.vue";
import BaseSpinner from "@/components/BaseSpinner.vue";

export default {
  components: {
    BaseDialog,
    BaseSpinner,
  },
  data() {
    return {
      speech: 'For millions of years... mankind lived just like the animals. Then, something happened which unleashed the power of our imagination. We learned to talk.',
      // url: null,
      error: '',
      isLoading: false
    }
  },
  methods: {
    async synthesize() {
      if (this.speech === '') {
        this.error = {
          message: 'Please enter some text to synthesize'
        }
        return;
      }

      try {
        this.isLoading = true;

        const synthesizeSpeechParams = {
          Engine: "standard",
          LanguageCode: "en-US",
          OutputFormat: "mp3",
          SampleRate: "16000",
          Text: this.speech,
          VoiceId: "Matthew"
        };

        const credentials = await this.$store.dispatch('auth/getCredentials');
        const url = await getSynthesizeSpeechUrl({
          client: new Polly({
            region: config.region,
            credentials:  {
              accessKeyId: credentials.AccessKeyId,
              secretAccessKey: credentials.SecretKey,
              sessionToken: credentials.SessionToken
            }
          }),
          params: synthesizeSpeechParams,
        });

        document.getElementById('audioSource').src = url;
        await document.getElementById('audioPlayback').load();
      }
      catch (error) {
        if (!this.$store.getters['auth/isAuthenticated']) {
          // const redirectUrl = '/' + (this.$route.query.redirect || 'polly')
          // this.$router.replace(redirectUrl)
          // this.$router.replace()
        } else {
          // todo: capture text to synthesize
          // const redirect = 'polly'
          this.$router.replace('/signin?redirect=polly')
          console.error(error)
          this.error = error;
        }
      }

      this.isLoading = false;
    },
    handleError() {
      this.error = null
    }
  },
}
</script>
