<template>
  <loading-modal v-if="isLoading" title="Loading..."/>

  <div class="communications-list">
    Subject: <a href="#" @click="toggleModal">{{ emailDetail.subject }}</a>
  </div>

  <div v-if="showModal" class="modal-backdrop" @click="toggleModal">
    <div class="modal-content" @click.stop>
      <div class="modal-header">
        <h3>{{ emailDetail.subject }}</h3>
        <button @click="toggleModal">&times;</button>
      </div>
      <div class="modal-body">
        <div v-html="body" class="email-body-full"></div>
      </div>
    </div>
  </div>
</template>
<script>
import LoadingModal from "@/components/LoadingModal.vue";
import {useStore} from "vuex";
import {onBeforeMount, ref} from "vue";
import DOMPurify from "dompurify";

function sanitize (html) {
  return DOMPurify.sanitize(html, {
    ALLOWED_TAGS: ['b', 'i', 'em', 'strong', 'a', 'p', 'br', 'div', 'span', 'ul', 'ol', 'li'],
    ALLOWED_ATTR: ['href', 'target', 'style']
  })
}

export default {
  components: {LoadingModal},
  props: {
    communicationId: {
      type: String,
      required: true
    }
  },
  setup(props) {
    const store = useStore();

    const isLoading = ref(false);
    const emailDetail = ref({});
    const body = ref('');

    const showModal = ref(false);

    onBeforeMount(async () => {
      try {
        isLoading.value = true;

        emailDetail.value = await store.dispatch('email/loadEmailDetail', props.communicationId)

        body.value = emailDetail.value.htmlBody ? sanitize(emailDetail.value.htmlBody) : emailDetail.value.textBody;
      } finally {
        isLoading.value = false;
      }
    })

    const toggleModal = () => {
      showModal.value = !showModal.value;
    }

    return {
      isLoading,
      emailDetail,
      body,
      showModal,
      toggleModal
    }
  }
}
</script>
<style scoped>
.modal-backdrop {
  position: fixed;
  top: 150px;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background: white;
  padding: 20px;
  border-radius: 8px;
  width: 80%;
  max-width: 800px;
  max-height: 90vh;
  overflow-y: auto;
}

.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
}

.email-body-full {
  padding: 20px;
  line-height: 1.6;
}
</style>