import axios from "axios";

export const products =  {
    namespaced: true,
    state: {
        loaded: false,
        productTypes: [],
        products: [],
        searchResults: null,
        searchParams: {},
        productDetails: [],
    },
    getters: {
        productTypes: (state) => state.productTypes,
        products: (state) => state.products,
        searchResults: (state) => state.searchResults,
        searchParams: (state) => state.searchParams,
        productDetails: (state) => state.productDetails,
    },
    mutations: {
        productTypes(state, productTypes) {
            state.productTypes = productTypes
        },
        products(state, products) {
            state.products = products
        },
        setSearchResults(state, searchResults) {
            state.searchResults = searchResults
        },
        setSearchParams(state, searchParams) {
            state.searchParams = searchParams
        },
    },
    actions: {
        async loadTypes(context) {
            if (context.getters['productTypes'].length > 0) {
                return context.getters['productTypes']
            }

            const host = 'api.mossbeachlife.com';
            const path = `/admin/products/types`;

            const getRequest = {
                method: 'GET',
                hostname: host,
                path: path,
                headers: {
                    host: host,
                },
            }

            const signedRequest = await context.dispatch('auth/signRequest', getRequest, {root: true});

            const headers = signedRequest.headers;
            delete headers['host'];

            const url = process.env.VUE_APP_API_URL + path;
            const response = await axios.get(url, {
                headers: headers
            });

            const productTypes = response.data;

            await context.commit('productTypes', productTypes);

            return productTypes
        },

        async clearSearch(context) {
            context.commit('setSearchParams', {})
            context.commit('setSearchResults', null)
            // context.commit('setProductDetails', [])
            context.getters['productDetails'].length = 0
        },
        async loadProductDetail(context, productId) {
            if (context.getters['productDetails'].length > 0) {
                const productDetail = context.getters['productDetails'].find(product => product.productId === productId);
                if (productDetail) {
                    return productDetail;
                }
            }

            // do query
            const host = 'api.mossbeachlife.com';
            const path = `/admin/products/${productId}`;

            const getRequest = {
                method: 'GET',
                hostname: host,
                path: path,
                headers: {
                    host: host,
                },
                // Add query parameters to the request object
                // query: queryParams
            }

            const signedRequest = await context.dispatch('auth/signRequest', getRequest, {root: true});

            const headers = signedRequest.headers;
            delete headers['host'];

            const url = process.env.VUE_APP_API_URL + path;
            const response = await axios.get(url, {
                headers: headers
            });

            const productDetails = response.data;
            await context.getters['productDetails'].push(productDetails)
            return productDetails;
        },
        async loadProductGroup(context, payload) {

            const {type, group} = payload

            const queryParams = {
                type: type,
                group: group
            }

            // do query
            const host = 'api.mossbeachlife.com';
            const path = '/admin/products';

            let pathWithQuery = path
            if (queryParams) {
                const queryString = new URLSearchParams(queryParams).toString();
                if (queryString) {
                    pathWithQuery = `${path}?${queryString}`;
                }
            }

            const getRequest = {
                method: 'GET',
                hostname: host,
                path: path,
                headers: {
                    host: host,
                },
                // Add query parameters to the request object
                query: queryParams
            }

            const signedRequest = await context.dispatch('auth/signRequest', getRequest, {root: true});

            const headers = signedRequest.headers;
            delete headers['host'];

            const url = process.env.VUE_APP_API_URL + pathWithQuery;
            const response = await axios.get(url, {
                headers: headers
            });

            const items = response.data.items;
            if (items.length > 1) {
                throw new Error('Expecting at most 1 result for type['+type+'] + group['+group+'], found ' + items.length);
            } else if (items.length > 0) {
                return items[0];
            } else {
                return null;
            }
        },
        async searchProducts(context, payload) {
            if (payload.page) {
                if (context.getters.searchResults) {
                    const searchResults = context.getters.searchResults
                    if (searchResults.pages) {
                        const pages = searchResults.pages;
                        const page = payload.page.toString();
                        if(pages[page]) {
                            return pages[page];
                        }
                    }
                }
            }

            const queryParams = payload.filters || context.getters.searchParams || {};
            await context.commit('setSearchParams', queryParams)

            // do not store page as part of locally stored searchParams
            if (payload.page) {
                queryParams.page = payload.page.toString();
            }

            // do query
            const host = 'api.mossbeachlife.com';
            const path = '/admin/products';

            let pathWithQuery = path
            if (queryParams) {
                const queryString = new URLSearchParams(queryParams).toString();
                if (queryString) {
                    pathWithQuery = `${path}?${queryString}`;
                }
            }

            const getRequest = {
                method: 'GET',
                hostname: host,
                path: path,
                headers: {
                    host: host,
                },
                // Add query parameters to the request object
                query: queryParams
            }

            const signedRequest = await context.dispatch('auth/signRequest', getRequest, {root: true});

            const headers = signedRequest.headers;
            delete headers['host'];

            const url = process.env.VUE_APP_API_URL + pathWithQuery;
            const response = await axios.get(url, {
                headers: headers
            });

            const pagination = response.data.pagination;
            const currentPage = pagination.currentPage;
            const totalPages = pagination.totalPages;

            const items = response.data.items;

            const searchResults = context.getters.searchResults;
            if (searchResults) {
                searchResults.pages[currentPage] = items;
                await context.commit('setSearchResults', searchResults);
            } else {
                const results = {
                    totalPages: totalPages,
                    pages: {
                        // is this key a string?
                        [currentPage]: items
                    }
                }
                await context.commit('setSearchResults', results);
            }

            return items;
        },

        async updateProduct2(context, product) {
            const productId = product.productId;

            delete product.productId
            const body = {
                product: product
            }

            const host = 'api.mossbeachlife.com';
            const path = `/admin/products/${productId}`;

            const putRequest = await context.dispatch('auth/getPUTRequest', {
                host: host,
                path: path,
                contentType: 'application/json',
                body: body
            }, {root: true});

            const signedRequest = await context.dispatch('auth/signRequest', putRequest, {root: true});

            const headers = signedRequest.headers;
            delete headers['host'];

            const url = process.env.VUE_APP_API_URL + path

            const response = await axios.put(url, body,{
                headers: headers
            });

            return response.data;
        },

        async updateProductGroup(context, payload) {
            const {group, products} = payload;
            const updatePromises = products.map(async (product) => {
                return await context.dispatch('updateProduct2', {
                    productId: product.productId,
                    type: group.type,
                    group: group.groupName,
                    description: group.groupDescription,
                    selectName: group.selectName,
                    productImageUrl: group.groupImageUrl
                });
            });
            // Wait for all product updates to complete
            await Promise.all(updatePromises);
        },

        async updateProductDetail(context, payload) {
            const {productId, productDetail} = payload;

            await context.dispatch('updateProduct2', {
                productId,
                ...productDetail
            });
        },

        async createProduct(context, product) {
            const body = {
                products: [product]
            }

            const host = 'api.mossbeachlife.com';
            const path = `/admin/products`;

            const postRequest = await context.dispatch('auth/getPOSTRequest', {
                host: host,
                path: path,
                contentType: 'application/json',
                body: body
            }, {root: true});

            const signedRequest = await context.dispatch('auth/signRequest', postRequest, {root: true});

            const headers = signedRequest.headers;
            delete headers['host'];

            const url = process.env.VUE_APP_API_URL + path

            const response = await axios.post(url, body,{
                headers: headers
            });

            // add type to productTypes (if new)
            const types = context.getters.productTypes.map(t => t.toUpperCase());
            const newType = product.type.toUpperCase();
            if (!types.includes(newType)) {
                context.getters.productTypes.push(newType)
            }

            return response.data;
        },

        async deleteProduct(context, productId) {

            // do query
            const host = 'api.mossbeachlife.com';
            const path = `/admin/products/${productId}`;

            const getRequest = {
                method: 'DELETE',
                hostname: host,
                path: path,
                headers: {
                    host: host,
                },
                // Add query parameters to the request object
                // query: queryParams
            }

            const signedRequest = await context.dispatch('auth/signRequest', getRequest, {root: true});

            const headers = signedRequest.headers;
            delete headers['host'];

            const url = process.env.VUE_APP_API_URL + path;
            await axios.delete(url, {
                headers: headers
            });
        },
        async loadCatalogPreview(context) {
            // do query
            const host = 'api.mossbeachlife.com';
            const path = '/admin/products/catalog';

            const getRequest = {
                method: 'GET',
                hostname: host,
                path: path,
                headers: {
                    host: host,
                },
            }
            const signedRequest = await context.dispatch('auth/signRequest', getRequest, {root: true});

            const headers = signedRequest.headers;
            delete headers['host'];

            const url = process.env.VUE_APP_API_URL + path;
            const response = await axios.get(url, {
                headers: headers
            });

            return response.data;
        },
        async publishCatalog(context) {
            const body = {}

            const host = 'api.mossbeachlife.com';
            const path = `/admin/products/catalog`;

            const postRequest = await context.dispatch('auth/getPOSTRequest', {
                host: host,
                path: path,
                contentType: 'application/json',
                body: body
            }, {root: true});

            const signedRequest = await context.dispatch('auth/signRequest', postRequest, {root: true});

            const headers = signedRequest.headers;
            delete headers['host'];

            const url = process.env.VUE_APP_API_URL + path
            await axios.post(url, body,{
                headers: headers
            });
        }
    }
}