<template>
  <div class="container">
    <h2>Print Search Results</h2>

    <div class="mb-3">
      <router-link :to="{name: 'prints-search'}" class="btn btn-outline-secondary">
        <i class="fas fa-arrow-left"></i> Back to Search
      </router-link>
    </div>

    <div v-if="formatGroups && formatGroups.length > 0">
      <table class="table">
        <thead>
          <tr>
        <th @click="toggleSort('format')" class="text-start sortable-th">
              Format
            <span v-if="getSortDirection('format')" class="sort-indicator">
              {{ getSortIndex('format') }}
              <i :class="['fas',getSortDirection('format') === 'asc' ? 'fa-sort-up' : 'fa-sort-down']"></i>
              </span>
            </th>
            <th class="text-center" style="width: 100px;">
            <!-- -->
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="formatGroup in formatGroups" :key="formatGroup.format">
            <td colspan="2">
              <search-result :formatGroup="formatGroup"/>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div v-else>
      No results found
    </div>
  </div>

<!-- TODO
  <nav aria-label="Page navigation">
    <ul class="pagination justify-content-center">
      &lt;!&ndash; Previous button &ndash;&gt;
      <li class="page-item" :class="{ disabled: page === 1 }">
        <a class="page-link" href="#" @click="gotoPage(page - 1)" aria-label="Previous">
          <span aria-hidden="true">&laquo;</span>
        </a>
      </li>
      &lt;!&ndash; Page numbers &ndash;&gt;
      <li v-for="pageNum in displayedPages"
          :key="pageNum"
          class="page-item"
          :class="{ active: pageNum === page }">
        <a class="page-link"
           href="#"
           @click="gotoPage(pageNum)">{{ pageNum }}</a>
      </li>
      &lt;!&ndash; Next button &ndash;&gt;
      <li class="page-item" :class="{ disabled: page === pages }">
        <a class="page-link" href="#" @click="gotoPage(page + 1)" aria-label="Next">
          <span aria-hidden="true">&raquo;</span>
        </a>
      </li>
    </ul>
  </nav>-->
</template>
<script>
import {ref, watch} from "vue";
import {useStore} from "vuex";
import SearchResult from "@/components/search/SearchResult.vue";

export default {
  components: {
    SearchResult
  },
  setup() {

    const store = useStore();

    const formatGroups = ref(store.state.search.searchResults);
    const sortFields = ref([])

    // Watch the state directly
    watch(() => store.state.search.searchResults, (newResults) => {
      formatGroups.value = newResults;
    }, { deep: true })

    const sortResults = function () {
      formatGroups.value.sort((a, b) => {
        for (const sort of sortFields.value) {
          // Handle non-date fields
          if (a[sort.field] !== b[sort.field]) {
            return sort.order === 'asc'
                ? a[sort.field] > b[sort.field] ? 1 : -1
                : a[sort.field] < b[sort.field] ? 1 : -1;
          }
        }
        return 0;
      });
    }

    const getSortDirection = function (field) {
      const sort = sortFields.value.find((s) => s.field === field)
      return sort ? sort.order : null;
    }

    const toggleSort = function(field) {
      const existingSort = sortFields.value.find((s) => s.field === field)
      if (existingSort) {
        existingSort.order = existingSort.order === 'asc' ? 'desc' : 'asc'
      } else {
        sortFields.value.push({
          field: field,
          order: 'asc'
        })
      }

      sortResults();
    }

    const getSortIndex = function (field) {
      const index = sortFields.value.findIndex((s) => s.field === field)
      return index !== -1 ? index + 1 : null;
    }

    return {
      formatGroups,
      toggleSort,

      getSortDirection,
      getSortIndex
    }
  }
}
</script>
<style scoped>
.sortable-th {
  cursor: pointer;
}

.sort-indicator {
  margin-left: 5px;
  font-size: 0.8em;
}
</style>