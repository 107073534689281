import { createStore } from 'vuex'
import { auth } from "@/store/auth";
import { products } from "@/store/products";
import { prints } from "@/store/prints";
import { email } from "@/store/email";
import { orders } from "@/store/orders";
import { catalog } from "@/store/catalog";
import { search } from "@/store/search";

export default createStore({
  modules: {
    auth,
    products,
    prints,
    email,
    orders,
    catalog,
    search,
  }
});
