<template>
  <nav class="navbar navbar-expand-md navbar-light bg-light bg-body py-3 fixed-top">
    <div class="container">
      <router-link to="/" class="navbar-brand d-flex align-items-center">
        <span>
          MossBeachLife.com
        </span>
      </router-link>

      <button
              class="navbar-toggler"
              :class="visible ? '' : 'collapsed'"
              :aria-expanded="visible ? 'true' : 'false'"
              data-bs-toggle="collapse"
              data-bs-target="#navbarNav"
              @click="visible = !visible">
        <span class="visually-hidden"></span>
        <span class="navbar-toggler-icon"></span>
      </button>

      <div id="navbarNav"
           class="collapse navbar-collapse flex-grow-0"
           :class="visible ? 'show' : ''"
      >
        <ul class="navbar-nav mx-auto">
          <li v-if="!isAuthenticated" class="nav-item" >
            <router-link class="nav-link"
                         :class="$route.name === 'SignInPage' ? 'active' : ''"
                         :to="{ name: 'SignInPage' }"
                         @click="visible = false">
              Login
            </router-link>
          </li>
          <li v-if="isAuthenticated" class="nav-item">
            <router-link class="nav-link"
                         :class="$route.name === 'home' ? 'active' : ''"
                         :to="{ name: 'home' }"
                         @click="visible = false">
              Home
            </router-link>
          </li>
          <li v-if="isAuthenticated" class="nav-item">
            <router-link class="nav-link"
                         :class="$route.name === 'orders-search' ? 'active' : ''"
                         :to="{ name: 'orders-search' }"
                         @click="visible = false">
              Orders
            </router-link>
          </li>
          <li v-if="isAuthenticated" class="nav-item">
            <router-link class="nav-link"
                         :class="$route.name === 'comms-search' ? 'active' : ''"
                         :to="{ name: 'comms-search' }"
                         @click="visible = false">
              Communications
            </router-link>
          </li>
          <li v-if="isAuthenticated" class="nav-item">
            <router-link class="nav-link"
                         :class="$route.name === 'email' ? 'active' : ''"
                         :to="{ name: 'email' }"
                         @click="visible = false">
              Email
            </router-link>
          </li>
          <li v-if="isAuthenticated" class="nav-item dropdown">
            <a class="nav-link dropdown-toggle"
               href="#"
               role="button"
               data-bs-toggle="dropdown"
               aria-expanded="false"
               :class="$route.name === 'products' ? 'active' : ''">
              Products
            </a>
            <ul class="dropdown-menu">
              <li>
                <router-link class="dropdown-item"
                             to="/products/search"
                             @click="visible = false">
                  Search Products
                </router-link>
              </li>
              <li>
                <router-link class="dropdown-item"
                             to="/products/create"
                             @click="visible = false">
                  Create Product
                </router-link>
              </li>
              <li>
                <router-link class="dropdown-item"
                             to="/products/catalog"
                             @click="visible = false">
                  Catalog
                </router-link>
              </li>
            </ul>
          </li>
          <li v-if="isAuthenticated" class="nav-item dropdown">
            <a class="nav-link dropdown-toggle"
               href="#"
               role="button"
               data-bs-toggle="dropdown"
               aria-expanded="false"
               :class="$route.name === 'prints' ? 'active' : ''">
              Prints
            </a>
            <ul class="dropdown-menu">
              <li>
                <router-link class="dropdown-item"
                             to="/prints/search"
                             @click="visible = false">
                  Search Prints
                </router-link>
              </li>
              <li>
                <router-link class="dropdown-item"
                             to="/prints/create"
                             @click="visible = false">
                  Create Print
                </router-link>
              </li>
              <li>
                <router-link class="dropdown-item"
                             to="/prints/catalog"
                             @click="visible = false">
                  Catalog
                </router-link>
              </li>
            </ul>
          </li>
            <!-- Polly is only used to test authentication logic -->
            <!--
            <li v-if="isAuthenticated" class="nav-item">
            <router-link class="nav-link"
                         :class="$route.name === 'polly' ? 'active' : ''"
                         :to="{ name: 'polly' }"
                         @click="visible = false">
              Polly
            </router-link>
          </li>-->
          <li v-if="isAuthenticated" class="nav-item" >
            <!-- TODO: sub menu of different products -->
            <button class="nav-link" @click="logout">Logout</button>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>
<script>

// https://github.com/robcresswell/vue-material-design-icons
// https://pictogrammers.com/library/mdi/
import {useStore} from "vuex";
import {useRouter} from "vue-router";
import {computed, ref} from "vue";

export default {
  setup() {

    const store = useStore();
    const router = useRouter();

    const visible = ref(false);

    const isAuthenticated = computed(() => {
      return store.getters["auth/isAuthenticated"]
    })

    const logout = async () => {
      visible.value = false;
      await store.dispatch("auth/logout");
      await router.push('/signin');
    };

    return {
      isAuthenticated,
      logout,
      visible: false
    }
  }
}
</script>
<style>
/* Add these styles to your component */
.nav-item {
  display: flex;
  justify-content: center; /* Centers items horizontally */
}

/* Specific styling for the logout button */
.nav-item .nav-link {
  text-align: center;
  width: 100%; /* Makes the button take full width on mobile */
}

/* For larger screens, you might want to reset these styles */
@media (min-width: 768px) { /* Bootstrap's md breakpoint */
  .nav-item {
    justify-content: flex-start; /* Aligns items to the left on larger screens */
  }

  .nav-item .nav-link {
    width: auto; /* Returns to default width on larger screens */
  }
}

/* Add or modify these styles in your component */
.nav-item.dropdown {
  position: relative;
}

.dropdown-menu {
  min-width: 200px;
  position: absolute;
  top: 100%; /* Positions the dropdown right below the menu item */
  left: 0;
  margin-top: 0;
  border-radius: 4px;
  box-shadow: 0 2px 5px rgba(0,0,0,0.2);
}

/* Optional: Add a small animation for smooth appearance */
.dropdown-menu.show {
  animation: fadeIn 0.2s ease-in;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.dropdown-item:active {
  background-color: #0d6efd;
}

.dropdown-item:hover {
  background-color: #f8f9fa;
}

/* For mobile responsiveness */
@media (max-width: 768px) {
  .dropdown-menu {
    width: 100%;
    position: static;
    box-shadow: none;
    border: none;
    background-color: #f8f9fa;
  }
}

</style>