import axios from "axios";

export const catalog =  {
    namespaced: true,
    state: {

    },
    getters: {

    },
    mutations: {

    },
    actions: {
        async loadCatalogPreview(context) {
            // do query
            const host = 'api.mossbeachlife.com';
            const path = '/admin/catalog';

            const getRequest = {
                method: 'GET',
                hostname: host,
                path: path,
                headers: {
                    host: host,
                },
            }
            const signedRequest = await context.dispatch('auth/signRequest', getRequest, {root: true});

            const headers = signedRequest.headers;
            delete headers['host'];

            const url = process.env.VUE_APP_API_URL + path;
            const response = await axios.get(url, {
                headers: headers
            });

            return response.data;
        },
        async publishCatalog(context) {
            const body = {}

            const host = 'api.mossbeachlife.com';
            const path = `/admin/catalog`;

            const postRequest = await context.dispatch('auth/getPOSTRequest', {
                host: host,
                path: path,
                contentType: 'application/json',
                body: body
            }, {root: true});

            const signedRequest = await context.dispatch('auth/signRequest', postRequest, {root: true});

            const headers = signedRequest.headers;
            delete headers['host'];

            const url = process.env.VUE_APP_API_URL + path
            await axios.post(url, body,{
                headers: headers
            });
        }
    }
}