<template>

  <loading-modal v-if="isLoading" title="Loading..."/>

  <loading-modal v-if="isPublishing" title="Publishing..."/>

  <div class="container">
    <h1>Products Catalog</h1>

    <button v-if="!catalog"
            class="btn btn-outline-secondary"
            @click.prevent="loadCatalogPreview">
      Load Preview
    </button>

    <div v-if="catalog" class="json-container mb-5">
      <json-viewer :json="catalog"/>
    </div>

    <div v-if="catalog" class="action-buttons justify-content-center">
      <button class="btn btn-outline-secondary" @click.prevent="clear">Clear</button>
      <button class="btn btn-outline-secondary" @click.prevent="publish">Publish</button>
    </div>
  </div>
</template>
<script>
import LoadingModal from "@/components/LoadingModal.vue";
import Swal from "sweetalert2";
import {computed, ref} from "vue";
import {useStore} from "vuex";
import JsonViewer from "@/components/catalog/JsonViewer.vue";

export default {
  name: 'CatalogView',
  components: {JsonViewer, LoadingModal},
  setup() {

    const store = useStore()

    const isLoading = ref(false)
    const isPublishing = ref(false)

    const catalog = ref(null)

    const loadCatalogPreview = async function () {
      try {
        isLoading.value = true

        const preview = await store.dispatch('products/loadCatalogPreview')
        catalog.value = preview
      } catch (error) {
        console.error(error)
        new Swal({
          title: 'Error!',
          text: 'We encountered a problem: ' + error.message,
          icon: 'error'
        });
      } finally {
        isLoading.value = false
      }
    }

    const clear = function () {
      catalog.value = null
    }

    const publish = async function () {
      try {
        isPublishing.value = true

        await store.dispatch('products/publishCatalog', catalog.value)

        isPublishing.value = false
        new Swal({
          title: 'Success!',
          text: 'Catalog published successfully',
          icon: 'success'
        });
        catalog.value = null
      } catch (error) {
        console.error(error)
        new Swal({
          title: 'Error!',
          text: 'We encountered a problem: ' + error.message,
          icon: 'error'
        });
      } finally {
        isPublishing.value = false
      }
    }

    const formattedCatalog = computed(() => {
      if (!catalog.value) return '';

      return JSON.stringify(catalog.value, null, 2);
    })

    return {
      isLoading,
      isPublishing,
      loadCatalogPreview,
      catalog,
      formattedCatalog,
      clear,
      publish,
    }
  }
}
</script>
<style scoped>
.json-container {
  background-color: #f8f9fa;
  padding: 20px;
  border-radius: 4px;
  border: 1px solid #dee2e6;
  margin-top: 20px;
}
</style>