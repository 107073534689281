<template>
  <div class="modal-dialog  modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header  bg-light">
        <h5 class="modal-title">Shipping Label</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body d-flex justify-content-center align-items-center p-4">
        <div class="shipping-label bg-white p-4 border border-1 rounded">
    <div class="shipping-info">
      <div class="order-details border-top mb-5">
        <p class="mb-4"><strong>Order #:</strong> {{ order.orderId }}</p>
        <h5 class="text-start p-0 mb-2 fw-bold">From:</h5>
        <p class="h5 p-0 mb-1">Moss Beach Life</p>
        <p class="h5 p-0 mb-1">170 Los Banos Ave</p>
        <p class="h5 p-0 mb-1">Moss Beach, CA 94038</p>
        <p v-if="order.shipToCountry !== 'US'" class="h5 p-0 mb-1">USA</p>
      </div>

      <!-- To Address -->
      <div class="to-address">
        <h5 class="text-start p-0 mt-2 fw-bold">Ship To:</h5>
        <p class="h5 p-0 mb-1">{{ order.shipToName }}</p>
        <p class="h5 p-0 mb-1">{{ order.shipToAddress }}</p>
        <p class="h5 p-0 mb-1">{{ order.shipToCity }}, {{ order.shipToState }}  {{ order.shipToPostalCode }}</p>
        <p v-if="order.shipToCountry !== 'US'" class="h5 p-0 mb-1">{{ order.shipToCity }}, {{ order.shipToState }}  {{ order.shipToPostalCode }}</p>
      </div>
    </div>
  </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
        <button type="button" class="btn btn-primary" @click="printLabel">
          <i class="bi bi-printer"></i> Print
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    order: {
      type: Object,
      required: true
    }
  },
  setup() {
    const printLabel = () => {
      const printContent = document.querySelector('.shipping-label')
      const WinPrint = window.open('', '', 'width=800,height=900')

      WinPrint.document.write(`
    <html lang="en">
      <head>
        <title>Shipping Label</title>
        <style>
          @media print {
            .shipping-label {
              width: 4in;
              height: 6in;
              padding: 0.25in;
              margin: 0 auto;
              border: 1px solid #000;
            }
            .shipping-info {
              font-family: Arial, sans-serif;
              font-size: 12pt;
            }
            .from-address, .to-address {
              margin-bottom: 20px;
            }
            .to-address {
              font-size: 14pt;
              font-weight: bold;
            }
            .order-details {
              margin-top: 20px;
              font-size: 10pt;
            }
            h6 {
              margin: 0;
              font-size: 10pt;
              text-transform: uppercase;
            }
            p {
              margin: 2px 0;
            }
          }
        </style>
      </head>
      <body>
        ${printContent.innerHTML}
      </body>
    </html>
  `)

      WinPrint.document.close()
      WinPrint.focus()
      setTimeout(() => {
        WinPrint.print()
        WinPrint.close()
      }, 250)
    }

    return {
      printLabel
    }
  }
}
</script>
<style scoped>
.shipping-label {
  width: 4in;
  height: 6in;
  margin: 0 auto;
}

/* Print-specific styles */
@media print {
  .shipping-label {
    border: 1px solid #000 !important;
    padding: 0.25in !important;
  }

  .from-address h5,
  .to-address h5 {
    font-size: 12pt !important;
    margin-bottom: 0.1in !important;
  }

  .from-address p,
  .to-address p {
    font-size: 12pt !important;
    margin-bottom: 0.05in !important;
  }

  .to-address p {
    font-weight: bold !important;
  }

  .order-details {
    font-size: 10pt !important;
    border-top: 1px solid #000 !important;
    padding-top: 0.1in !important;
  }

  .order-details p {
    margin-bottom: 0.05in !important;
  }
}
</style>
