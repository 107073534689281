<template>
  <div class="detail-container">
    <div class="info-row">
      <span class="label">Print ID:</span>{{printResult.printId}}
    </div>

    <div class="info-row">
      <span class="label">Name:</span>{{printResult.name}}
    </div>
    <div class="info-row">
      <span class="label">Size:</span>{{printResult.size}}
    </div>

    <div class="info-row finishes">
      <span class="label">Finishes:</span>
      <span class="value finishes-value">
    <template v-for="(finish, index) in printResult.printDetail.finishes" :key="index">
      <span :class="{ 'first-finish': index === 0, 'subsequent-finish': index > 0 }">
        {{ finish.trim() }}
      </span>
    </template>
  </span>
    </div>

    <div class="info-row">
      <span class="label">Unit Price:</span>{{printResult.printDetail.unitPrice}}
    </div>

    <div class="info-row">
      <span class="label">Dimensions:</span>{{printResult.printDetail.length}} * {{printResult.printDetail.width}} {{printResult.printDetail.height}}
    </div>

    <div class="info-row">
      <span class="label">Weight (oz):</span>{{printResult.printDetail.weight}}
    </div>

    <div class="info-row">
      <span class="label">Inventory:</span>{{printResult.printDetail.inventory}}
    </div>

    <div class="info-row">
      <span class="label">Updated:</span>{{printResult.updated}}
    </div>

    <!--
    "livemode": true,
    "active": true,
    "shippable": null,
    "stripeDescription": null,
    "statementDescriptor": null,
    "unitLabel": null,
    -->
  </div>
</template>
<script>
  export default {
    props: {
      printResult: {
        type: Object,
        required: true
      }
    }
  }
</script>
<style scoped>
.info-row {
  display: flex;          /* Changed to flex */
  align-items: center;    /* Vertically center content */
  width: 100%;           /* Take full width */
  padding: 4px 0;        /* Optional: adds some vertical spacing */
}

.label {
  flex: 0 0 120px;        /* Fixed width, won't grow or shrink */
  text-align: right;     /* Right align the label text */
  margin-right: 8px;     /* Space between label and value */
  font-weight: bold;
}

.info-row.finishes {
  align-items: flex-start;  /* Override for finish rows */
}

.info-row.finishes .label {
  padding-top: 4px;        /* Only add padding for finish label */
}

.finishes-value {
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: flex-start;
}

.first-finish {
  padding-top: 4px;      /* Match label's padding-top */
}

.subsequent-finish {
  margin-top: 4px;       /* Space between finish entries */
}
</style>