import { createRouter, createWebHistory } from 'vue-router'
import store from "@/store/store.js"
import HomeView from '../views/HomeView.vue'
import NotFoundPage from "@/views/NotFoundPage.vue";
import SignInPage from "@/views/SignInPage.vue";
import PollyPage from "@/views/PollyPage.vue";
import EmailView from "@/views/EmailView.vue";
import CommsView from "@/views/CommsView.vue";
import CommsSearch from "@/components/comms/CommsSearch.vue";
import CommsResults from "@/components/comms/CommsResults.vue";
import OrdersView from "@/views/OrdersView.vue";
import OrdersSearch from "@/components/orders/OrdersSearch.vue";
import OrdersResults from "@/components/orders/OrdersResults.vue";
import ProductsView2 from "@/views/ProductsView2.vue";
import ProductsSearch from "@/components/products/ProductsSearch.vue";
import ProductsResults from "@/components/products/ProductsResults.vue";
import ProductsCreate from "@/components/products/ProductsCreate.vue";
import PrintsView from "@/views/PrintsView.vue";
import PrintsCreate from "@/components/prints/PrintsCreate.vue";
import SearchPage from "@/components/search/SearchPage.vue";
import SearchResults from "@/components/search/SearchResults.vue";
import PrintsCatalog from "@/components/prints/PrintsCatalog.vue";
import ProductCatalog from "@/components/products/ProductsCatalog.vue";

const routes = [
  {
    path: '/signin',
    name: 'SignInPage',
    component: SignInPage,
    meta: {requiresNotAuth: true}
  },
  {
    path: '/',
    name: 'home',
    component: HomeView,
  },
  {
    path: '/polly',
    name: 'polly',
    component: PollyPage,
  },
  {
    path: '/email',
    name: 'email',
    component: EmailView,
  },
  {
    path: '/orders',
    name: 'orders',
    component: OrdersView,
    children: [
      {
        path: 'search',
        name: 'orders-search',
        component: OrdersSearch
      },
      {
        path: 'results',
        name: 'orders-results',
        component: OrdersResults
      },
    ]
  },
  {
    path: '/comms',
    name: 'comms',
    component: CommsView,
    children: [
      {
        path: 'search',
        name: 'comms-search',
        component: CommsSearch
      },
      {
        path: 'results',
        name: 'comms-results',
        component: CommsResults
      },
      {
        path: ':pathMatch(.*)*',
        redirect: {name: 'comms-search'}
      }
    ]
  },
  {
    path: '/products',
    name: 'products',
    component: ProductsView2,
    children: [
      {
        path: '', // This is the empty path under /prints
        name: 'products-default',
        redirect: {name: 'products-search'}
      },
      {
        path: 'search',
        name: 'products-search',
        component: ProductsSearch
      },
      {
        path: 'results',
        name: 'products-results',
        component: ProductsResults
      },
      {
        path: 'create',
        name: 'products-create',
        component: ProductsCreate
      },
      {
        path: 'catalog',
        name: 'products-catalog',
        component: ProductCatalog
      },
      {
        path: ':pathMatch(.*)*',
        redirect: {name: 'products-search'}
      }
    ]
  },
  {
    path: '/prints',
    name: 'prints',
    component: PrintsView,
    children: [
      {
        path: '', // This is the empty path under /prints
        name: 'prints-default',
        redirect: {name: 'prints-search'}
      },
      {
        path: 'search',
        name: 'prints-search',
        component: SearchPage
      },
      {
        path: 'results',
        name: 'prints-results',
        component: SearchResults
      },
      {
        path: 'create',
        name: 'prints-create',
        component: PrintsCreate
      },
      {
        path: 'catalog',
        name: 'prints-catalog',
        component: PrintsCatalog
      },
      {
        path: ':pathMatch(.*)*',
        redirect: {name: 'prints-search'}
      }
    ]
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  },
  {
    path: '/:notFound(.*)',
    component: NotFoundPage
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach(async (to, from, next) => {
  const authenticated = store.getters['auth/isAuthenticated']
  const requiresNotAuth = to.matched.some(record => record.meta.requiresNotAuth);
  if (requiresNotAuth) {
    if (!authenticated) {
      next();
    } else {
      next('/');
    }
  } else if (authenticated) {
      next();
  } else {
    let redirect = (to.href || '').replace(window.location.origin, '')
    if (redirect === '/') {
      next(`/signin`);
    } else {
      if (redirect.startsWith('/')) {
        redirect = redirect.slice(1)
      }
      next(`/signin?redirect=${encodeURIComponent(redirect)}`);
    }
  }
})

export default router
