<!-- JsonViewer.vue -->
<template>
  <div class="json-viewer text-start">
    <div v-if="isObject" class="json-item">
      <span class="toggle" @click="toggle">
        {{ isExpanded ? '▼' : '▶' }}
      </span>
      <span class="key" @click="toggle">
        <template v-if="keyName">{{ displayKey }}: </template>
        {{ isArray ? '[' : '{' }}
        <span v-if="!isExpanded">...</span>
      </span>
      <div v-if="isExpanded" class="content">
        <div v-for="(value, key) in json" :key="key" class="json-row">
          <json-viewer
              :json="value"
              :keyName="String(key)"
              :indent="indent + 1"
          />
        </div>
      </div>
      <span>{{ isArray ? ']' : '}' }}</span>
    </div>
    <div v-else class="json-item">
      <span class="key">{{ displayKey }}:</span>
      <span v-if="isLongString" class="value string">
        <span class="toggle-text" @click="toggleText">
          {{ isTextExpanded ? '▼' : '▶' }}
        </span>
        <span v-if="isTextExpanded">
          "{{ json }}"
        </span>
        <span v-else>
          "{{ truncatedText }}..."
        </span>
      </span>
      <span v-else class="value" :class="valueType">
        {{ formatValue(json) }}
      </span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'JsonViewer',
  props: {
    json: {
      type: [Object, Array, String, Number, Boolean, null],
      required: true
    },
    keyName: {
      type: [String, Number],
      default: ''
    },
    indent: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      isExpanded: true,
      isTextExpanded: false,
      maxLength: 80 // Maximum length before truncating
    }
  },
  computed: {
    displayKey() {
      return String(this.keyName)  // Convert to string for display
    },
    isObject() {
      return this.json !== null && (typeof this.json === 'object')
    },
    isArray() {
      return Array.isArray(this.json)
    },
    isLongString() {
      return typeof this.json === 'string' && this.json.length > this.maxLength
    },
    truncatedText() {
      return typeof this.json === 'string'
          ? this.json.slice(0, this.maxLength)
          : this.json
    },
    valueType() {
      if (this.json === null) return 'null'
      if (typeof this.json === 'string') return 'string'
      if (typeof this.json === 'number') return 'number'
      if (typeof this.json === 'boolean') return 'boolean'
      return ''
    }
  },
  methods: {
    toggle() {
      this.isExpanded = !this.isExpanded
    },
    toggleText() {
      this.isTextExpanded = !this.isTextExpanded
    },
    formatValue(value) {
      if (value === null) return 'null'
      if (typeof value === 'string') return `"${value}"`
      return value
    }
  }
}
</script>

<style scoped>
.json-viewer {
  font-family: monospace;
  font-size: 14px;
  margin-left: v-bind('indent * 10 + "px"');
}

.json-item {
  line-height: 1.5;
}

.toggle {
  cursor: pointer;
  user-select: none;
  margin-right: 5px;
}

.toggle, .toggle-text {
  cursor: pointer;
  user-select: none;
  margin-right: 5px;
  color: #666;
}

.toggle-text {
  font-size: 12px;
}

.key {
  color: #881391;
  cursor: pointer;
}

.value {
  margin-left: 5px;
}

.value.string {
  color: #c41a16;
  white-space: pre-wrap;
  word-break: break-word;
}

.value.number {
  color: #1c00cf;
}

.value.boolean {
  color: #0000ff;
}

.value.null {
  color: #808080;
}

.content {
  margin-left: 20px;
}
</style>
