<template>
  <footer class="footer mt-3">
    <div class="container">
      <div class="row row-cols-1 row-cols-sm-1 row-cols-md-4 g-2 justify-content-center align-items-center">
        <!-- Footer go here -->
        <div class="col text-center">
          <p class="m-0 text-dark">
            <i class="fa fa-copyright fa-lg" aria-hidden="true"></i>
            MossBeachLife.com
          </p>
        </div>
      </div>
    </div>
  </footer>
</template>
<script>
import {computed, onMounted, ref} from "vue";
import {useRoute} from "vue-router";

// https://github.com/robcresswell/vue-material-design-icons
// https://pictogrammers.com/library/mdi/
export default {
  setup() {

    const route = useRoute();

    const width = ref(0);
    const height = ref(0);

    const enhanced = computed(() => {
      return route.query.mode === 'enhanced'
    })

    const updateDimensions = () => {
      height.value = window.innerHeight;
      width.value = window.innerWidth;
    };

    onMounted(() => {
      updateDimensions();
      window.addEventListener('resize', updateDimensions)
    });

    return {
      width,
      height,
      enhanced
    }
  }
}
</script>

<style lang="scss" scoped>
.fa-facebook:hover {
  color: #3b5998;
}

.fa-instagram:hover {
  color: #e1306c;
}

.fa-camera:hover {
  color: #3C9F40;
}
</style>
