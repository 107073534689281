<template>
  <loading-modal v-if="isSaving" title="Saving..."/>

  <loading-modal v-if="isLoading" title="Loading..."/>

  <div class="container-fluid">
    <div class="row w-100"> <!-- Changed to row -->
      <div v-if="isEditing" class="col-12">
        <div class="d-flex w-100">
          <div class="flex-grow-1" style="width: calc(100% - 70px);"> <!-- Added fixed calculation for width -->

            <!-- Name -->
            <div class="row align-items-center mb-2">
              <label for="productName" class="col-2 col-form-label">
                Name:
              </label>
              <div class="col-10">
                <input
                    id="productName"
                    v-model="editableProductDetail.name"
                    class="form-control"
                    type="text"
                    required
                />
              </div>
            </div>

            <!-- ImageURL -->
            <div class="row align-items-center mb-2">
              <div class="col-2">
                <img v-if="editableProductDetail.imageUrl"
                     :src="editableProductDetail.imageUrl"
                     style="height: auto; width: auto; max-height: 30px;"
                     class="object-fit-contain"/>
                <label v-else
                       for="imageUrl"
                       class="col-form-label">Image URL:</label>
              </div>
              <div class="col-10">
                <input
                    id="imageUrl"
                    v-model="editableProductDetail.imageUrl"
                    class="form-control"
                    type="text"
                    placeholder="Enter Image URL"
                />
              </div>
            </div>

            <!-- Select Option -->
            <div class="row align-items-center mb-2">
              <label for="selectOption" class="col-2 col-form-label">
                Select Option [{{ selectName }}]:
              </label>
              <div class="col-10">
                <input
                    id="selectOption"
                    v-model="editableProductDetail.selectOption"
                    class="form-control"
                    type="text"
                    required
                />
              </div>
            </div>

            <!-- Unit Price -->
            <div class="row align-items-center mb-2">
              <label for="unitPrice" class="col-2 col-form-label">
                Unit Price:
              </label>
              <div class="col-10">
                <input
                    id="unitPrice"
                    v-model="editableProductDetail.unitPrice"
                    class="form-control"
                    type="number"
                    min="0"
                    required
                />
              </div>
            </div>

            <!-- Product URL -->
            <div class="row align-items-center mb-2">
              <label for="productUrl" class="col-2 col-form-label">
                Product URL:
              </label>
              <div class="col-10">
                <input
                    id="unitPrice"
                    v-model="editableProductDetail.productUrl"
                    class="form-control"
                    type="url"
                />
              </div>
            </div>

            <!-- Features -->
            <div class="row mb-2">
              <label for="features" class="col-2 col-form-label">
                Features:
              </label>
              <div class="col-10">
                <!-- Rows of features, with option to remove each row -->
                <div v-for="(feature, index) in editableProductDetail.features" :key="index" class="d-flex mb-2">
                  <input
                      :id="'feature-' + index"
                      v-model="editableProductDetail.features[index]"
                      type="text"
                      class="form-control me-2"
                      placeholder="Enter feature"
                  />
                  <button
                      class="btn btn-outline-danger"
                      @click.prevent="removeFeature(index)"
                  >
                    Remove
                  </button>
                </div>

                <!-- Button to add new row -->
                <div class="text-end">
                <button
                    class="btn btn-outline-secondary"
                    @click.prevent="addFeature"
                >
                  Add Feature
                </button>
                </div>
              </div>
            </div>

            <!-- Dimensions -->
            <div class="row align-items-center mb-2">
              <label for="length" class="col-2 col-form-label">
                Length (X):
              </label>
              <div class="col-10">
                <input
                    id="length"
                    v-model="editableProductDetail.length"
                    class="form-control"
                    type="number"
                    min="0"
                    required
                />
              </div>

            </div>
            <div class="row align-items-center mb-2">
              <label for="width" class="col-2 col-form-label">
                Width (Y):
              </label>
              <div class="col-10">
                <input
                    id="width"
                    v-model="editableProductDetail.width"
                    class="form-control"
                    type="number"
                    min="0"
                    required
                />
              </div>
            </div>
            <div class="row align-items-center mb-2">
              <label for="height" class="col-2 col-form-label">
                Thickness (Z):
              </label>
              <div class="col-10">
                <input
                    id="height"
                    v-model="editableProductDetail.height"
                    class="form-control"
                    type="number"
                    min="0"
                    required
                />
              </div>

            </div>
            <div class="row align-items-center mb-2">
              <label for="weight" class="col-2 col-form-label">
                Weight (oz):
              </label>
              <div class="col-10">
                <input
                    id="width"
                    v-model="editableProductDetail.width"
                    class="form-control"
                    type="number"
                    min="0"
                    required
                />
              </div>
            </div>

            <!-- Special Message -->
            <div class="row align-items-center mb-2">
              <label for="specialMessage" class="col-2 col-form-label">
                Special Message:
              </label>
              <div class="col-10">
                <textarea
                    id="specialMessage"
                    v-model="editableProductDetail.specialMessage"
                    class="form-control"
                    rows="3"
                ></textarea>
              </div>
            </div>

            <!-- SoldOut -->
            <div class="row align-items-center mb-2">
              <label for="soldOut" class="col-2 col-form-label">
                Sold Out:
              </label>
              <div class="col-10 text-start">
                <!-- checkbox to indicate if sold out OR input for quantity available-->
                <input type="checkbox" id="soldOut" v-model="editableProductDetail.soldOut">
              </div>
            </div>


            <!-- Inventory -->
            <div class="row align-items-center mb-2">
              <label for="inventory" class="col-2 col-form-label">
                Inventory:
              </label>
              <div class="col-10">
                <input
                    id="inventory"
                    v-model="editableProductDetail.inventory"
                    type="number"
                    class="form-control"
                    min="-1"
                    :disabled="editableProductDetail.soldOut"
                />
              </div>
            </div>

            <div class="row align-items-center mb-2">
              <div class="col-12">
                <button class="btn btn-outline-secondary me-2" @click.prevent="cancelEditing">Cancel</button>
                <button class="btn btn-outline-primary" @click.prevent="saveChanges">Save</button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-else class="col-12">
        <div class="">
          <div class="product-header">
            <div class="product-image">
              <img :src="productDetail.imageUrl"
                   alt="Product Image"
                   style="height: auto; width: auto; max-height: 75px; max-width: 100%;"
              />
            </div>
            <div class="product-title">
              <h3 class="fw-bolder m-0 mb-2">{{ productDetail.name }}</h3>
              <p class="text-start fs-4"><span class="fw-bolder">{{selectName}}:</span> {{ productDetail.selectOption }}</p>
            </div>
            <div class="product-edit ms-auto">
              <button class="btn btn-outline-secondary me-1" @click.prevent="editProduct">Edit</button>
              <button class="btn btn-outline-danger ms-1" @click.prevent="deleteProduct">Delete</button>
            </div>
          </div>
          <div class="product-detail">

            <div class="product-price mb-4">
              <p class="text-start fs-4"><span class="fw-bolder">Unit Price:</span> {{ productDetail.unitPrice }}</p>
            </div>

            <div class="product-url mb-4">
              <p class="text-start fs-4"><span class="fw-bolder">Product URL:</span> <a :href="productDetail.productUrl" target="_blank">{{productDetail.productUrl}}</a></p>
            </div>

            <div class="product-features mb-4">
              <p class="text-start fs-4 fw-bolder">Features:</p>
              <ul class="list-unstyled ps-4">
                <li v-for="feature in productDetail.features"
                    :key="feature"
                    class="fs-5 fw-lighter text-start">
                  {{ feature }}
                </li>
              </ul>
            </div>

            <div class="product-dimensions mb-4">
              <p class="text-start fs-4">
                <span class="fw-bolder">Dimensions (X*Y*Z):</span> {{ productDetail.length }} x {{ productDetail.width }} x {{ productDetail.height }},
                <span class="fw-bolder">Weight (oz):</span> {{ productDetail.weight }}
              </p>
            </div>

            <div class="product-special mb-4">
              <p class="text-start fs-4"><span class="fw-bolder">Special Message:</span> {{ productDetail.specialMessage }}</p>
            </div>

            <div class="product-inventory mb-4">
              <p class="text-start fs-4"><span class="fw-bolder">Inventory:</span> {{ productDetail.inventory < 0 ? 'Unlimited' : (productDetail.inventory > 0 ? productDetail.inventory : 'Out of Stock')  }}</p>
            </div>

            <div class="product-updates">
              <p class="text-start fs-4"><span class="fw-bolder">Modified:</span> {{ productDetail.updatedDate }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import LoadingModal from "@/components/LoadingModal.vue";
import {useStore} from "vuex";
import {onBeforeMount, ref, watch} from "vue";
import Swal from "sweetalert2";

export default {
  components: {LoadingModal},
  props: {
    productId: {
      type: String,
      required: true
    },
    selectName: {
      type: String,
      required: true
    }
  },
  setup(props, {emit}) {
    const store = useStore();

    const isLoading = ref(false);
    const productDetail = ref({});

    const isEditing = ref(false);
    const isSaving = ref(false);
    const editableProductDetail = ref({});

    onBeforeMount(async () => {
      try {
        isLoading.value = true;
        productDetail.value = await store.dispatch('products/loadProductDetail', props.productId)
      } catch (error) {
        console.error('error: ' + error);
        new Swal({
          title: 'Error!',
          text: 'We encountered a problem: ' + error.message,
          icon: 'error'
        });
      } finally {
        isLoading.value = false;
      }
    })

    const editProduct = function() {
      editableProductDetail.value = {
        name: productDetail.value.name,
        imageUrl: productDetail.value.imageUrl,
        productUrl: productDetail.value.productUrl,
        unitPrice: productDetail.value.unitPrice,
        selectOption: productDetail.value.selectOption,
        weight: productDetail.value.weight,
        length: productDetail.value.length,
        width: productDetail.value.width,
        height: productDetail.value.height,
        //  need deep clone of features

        features: JSON.parse(JSON.stringify(productDetail.value.features)),
        specialMessage: productDetail.value.specialMessage,
        soldOut: productDetail.value.soldOut,
        inventory: productDetail.value.inventory,
      };
      isEditing.value = true;
    }

    const deleteProduct = async function() {
      try {
        isSaving.value = true;

        await store.dispatch('products/deleteProduct', props.productId);

        emit('productDeleted', productDetail.value);
      } catch (error) {
        console.error('error: ' + error);
        new Swal({
          title: 'Error!',
          text: 'We encountered a problem: ' + error.message,
          icon: 'error'
        });
      } finally {
        isSaving.value = false;
      }
    }

    const addFeature = function () {
      if (!editableProductDetail.value.features) {
        editableProductDetail.value.features = [];
      }
      editableProductDetail.value.features.push('');
    }

    const removeFeature = function(index) {
      editableProductDetail.value.features.splice(index, 1);
    }

    const cancelEditing = function() {
      isEditing.value = false;
      editableProductDetail.value = {};
    }

    const saveChanges = async function() {
      try {
        isSaving.value = true;

        await store.dispatch('products/updateProductDetail', {
          productId: props.productId,
          productDetail: editableProductDetail.value
        });

        isEditing.value = false;

        emit('updated', props.productId);
      } catch (error) {
        console.error('error: ' + error);
        new Swal({
          title: 'Error!',
          text: 'We encountered a problem: ' + error.message,
          icon: 'error'
        });
      } finally {
        isSaving.value = false;
      }

    }

    watch(() => editableProductDetail.value.soldOut, (newValue) => {
      if (newValue) {
        // When soldOut is checked, set inventory to 0 and type to 'out'
        editableProductDetail.value.inventory = 0;
      } else {
        // When soldOut is unchecked, set inventory to -1 (unlimited) and type to 'unlimited'
        editableProductDetail.value.inventory = -1;
      }
    })

    return {
      isLoading,
      productDetail,
      editProduct,
      deleteProduct,
      isEditing,
      isSaving,
      editableProductDetail,
      addFeature,
      removeFeature,
      cancelEditing,
      saveChanges
    }
  },
  emits: ['updated', 'productDeleted'],
}
</script>
<style scoped>
.product-header {
  display: flex;
  align-items: flex-start;
  gap: 1rem;
}

.product-image {
  flex-shrink: 0;
}

.product-title {
  overflow: hidden;
}

.product-features ul {
  margin: 0;
}

.product-features li {
  margin-bottom: 0.5rem; /* Add some spacing between items */
}

.form-control {
  resize: vertical;
  min-height: 60px;
  width: 100%;
  max-width: 100%;
}

.d-flex {
  width: 100%;
}

.flex-grow-1 {
  min-width: 0; /* Prevents flex item from overflowing */
}

.col-form-label {
  padding-top: 0;
  padding-bottom: 0;
  white-space: nowrap;
  text-align: end;
  font-weight: bolder;
}
</style>