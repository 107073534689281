<template>
  <loading-modal v-if="isPosting" title="Sending..."/>

  <div class="container">
    <h1>Email</h1>
    <section id="form" class="py-2">
                <form @submit.prevent="submitForm">
                  <div class="mb-3 input-group">
                    <span class="input-group-text">Email @</span>
                    <input type="email" class="form-control" v-model.trim="contact"/>
                  </div>

                  <div class="mb-3 input-group">
                    <span class="input-group-text">Order ID</span>
                    <input type="text" class="form-control" v-model.trim="orderId"/>
                  </div>

                  <div class="mb-3 input-group">
                    <span class="input-group-text">From</span>
                    <select class="form-select" v-model="fromName">
                      <option value="order">Order</option>
                    </select>
                  </div>

                  <div class="mb-3 input-group">
                    <span class="input-group-text">Subject</span>
                    <input type="text" class="form-control" v-model.trim="subject"/>
                  </div>

                  <div class="mb-3 input-group">
                    <span class="input-group-text">Message Body</span>
                    <textarea class="form-control" rows="10" placeholder="Message" v-model.trim="message"></textarea>
                  </div>

                  <div class="mb-3 input-group">
                    <span class="input-group-text">Send Test Message</span>
                    <input type="checkbox" class="form-check-input mt-0"  v-model.trim="testMessage"/>
                  </div>

                  <div class="mb-3 d-flex justify-content-between">
                    <input type="button" value="Clear" class="btn bg-danger text-white btn-block" :class="isPosting ? 'disabled' : ''" @click="clearForm">
                    <input type="submit" value="Submit" class="btn btn-primary btn-block" :class="error ? 'disable' : ''">
                  </div>

                  <div v-if="isPosting">
                    <p class="text-center help-block text-success">POSTING...</p>
                  </div>
                  <div v-if="error">
                    <h1>An Error Occurred!</h1>
                    <p>{{error}}</p>
                    <button class="btn btn-danger" @click="handleError">Okay</button>
                  </div>
                </form>
    </section>
  </div>
</template>
<script>
import Swal from 'sweetalert2'
import {ref} from "vue";
import LoadingModal from "@/components/LoadingModal.vue";
import {useStore} from "vuex";

export default {
  components: {
    LoadingModal,
  },
  setup() {

    const store = useStore();

    const isPosting = ref(false);
    const error = ref('');
    const contact = ref('');
    const orderId = ref('');
    const fromName = ref('order');
    const subject = ref('');
    const message = ref('');
    const testMessage = ref(true);

    const handleError = function () {
      error.value = ''
    }

    const submitForm = async function () {
      try {


        if (!contact.value || /*!orderId.value || */!fromName.value || !subject.value || !message.value) {
          // throw new Error('Please fill out all fields.')
          error.value = 'Please fill out all fields.'
          return;
        }

        const request = {
          contact: contact.value,
          orderId: orderId.value,
          fromName: fromName.value,
          subject: subject.value,
          body: message.value,
          testMessage: testMessage.value
        }

        let result = await Swal.fire({
              title: testMessage.value ? 'Confirm TEST email' : 'Confirm ACTUAL email',
              text: testMessage.value ? 'You are sending a TEST email' : 'You are sending an actual email; No take-backs',
              icon: 'question',
              showCancelButton: true,
              confirmButtonColor: '#3085d6',
              cancelButtonColor: '#d33',
              confirmButtonText: 'Yes, send it!',
              cancelButtonText: 'Cancel'
        });

        if (result.isConfirmed) {
          isPosting.value = true;
          await store.dispatch('email/postEmail', request)

          if (!testMessage.value) {
            contact.value = ''
            orderId.value = ''
            subject.value = ''
            message.value = ''
            testMessage.value = true;
          }
        }
      } catch (error) {
        console.error('Exception: ' + JSON.stringify(error))
        new Swal({
          title: 'Error!',
          text: 'We encountered a problem: ' + error.message,
          icon: 'error'
        });
        error.value = error.message
      } finally {
        isPosting.value = false
      }
    }


    return {
      contact,
      orderId,
      fromName,
      subject,
      message,
      testMessage,

      isPosting,
      error,

      handleError,
      submitForm
    }
  }
}
</script>