<template>
  <loading-modal v-if="isLoading" title="Searching..."/>

  <div class="container">
    <h2>Order Search</h2>

    <div class="row">
      <div class="col-12">
        <div class="form-group">

          <form @submit.prevent="submitForm">
            <div class="mb-3 input-group">
              <span class="input-group-text">Status</span>
              <select class="form-select" v-model="orderStatus">
                <option value="">ALL</option>
                <option value="COMPLETE">COMPLETE</option>
                <option value="INITIATED">INITIATED</option>
                <option value="EXPIRED">EXPIRED</option>
              </select>
            </div>

            <div class="mb-3 input-group">
              <span class="input-group-text">Email</span>
              <input type="email" class="form-control" v-model.trim="email"/>
            </div>

            <div class="mb-3 input-group">
              <span class="input-group-text">OrderId</span>
              <input type="text" class="form-control" v-model.trim="orderId"/>
            </div>

            <div class="mb-3 input-group">
              <span class="input-group-text">Status</span>
              <select class="form-select" v-model="orderStatus">
                <option value="">ALL</option>
                <option value="COMPLETE">COMPLETE</option>
                <option value="EXPIRED">EXPIRED</option>
                <option value="INITIATED">INITIATED</option>
              </select>
            </div>

            <div class="mb-3 d-flex justify-content-between">
              <input type="button" value="Clear" class="btn bg-danger text-white btn-block" :class="isLoading ? 'disabled' : ''" @click="clearForm">
              <input type="submit" value="Submit" class="btn btn-primary btn-block">
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Swal from 'sweetalert2'
import LoadingModal from "@/components/LoadingModal.vue";
import {
  onBeforeMount,
  ref
} from "vue";
import {useStore} from "vuex";
import {useRouter} from "vue-router";

export default {
  components: {LoadingModal},
  setup() {

    const store = useStore();
    const router = useRouter();

    const isLoading = ref(false)

    const email = ref('');
    const orderId = ref('');
    const orderStatus = ref('COMPLETE')

    const submitForm = async () => {
      try {
        const queryParams = {}
        if (email.value) queryParams.email = email.value;
        if (orderId.value) queryParams.orderId = orderId.value;
        if (orderStatus.value) queryParams.orderStatus = orderStatus.value;

        isLoading.value = true;

        const results = await store.dispatch('orders/searchOrders', {filters: queryParams});
        if (results.length > 0) {
          router.push({
            name: 'orders-results',
          });
        } else {
          // show dialog no results found
          new Swal({
            title: 'No Results',
            text: 'No results found for your search criteria.',
            icon: 'info'
          });
        }
      } catch (error) {
        console.error('Exception: ' + JSON.stringify(error))
        new Swal({
          title: 'Error!',
          text: 'We encountered a problem: ' + error.message,
          icon: 'error'
        });
      } finally {
        isLoading.value = false;
      }
    }

    onBeforeMount(async () => {
      await store.dispatch('orders/clearSearch');
    })

    const clearForm = () => {
      email.value = ''
      orderId.value = ''
      orderStatus.value = ''
    }

    return {
      isLoading,
      email,
      orderId,
      orderStatus,

      submitForm,
      clearForm
    }
  }
}
</script>