<template>

  <loading-modal v-if="isLoading" title="Searching..."/>

  <div class="container">
    <h2>Order Search Results</h2>

    <div class="mb-3">
      <router-link :to="{name: 'orders-search'}" class="btn btn-outline-secondary">
        <i class="fas fa-arrow-left"></i> Back to Search
      </router-link>
    </div>

    <div v-if="results">

      <table class="table">
        <thead>
        <tr>
          <th @click="toggleSort('orderId')" style="cursor: pointer">
            OrderId
            <span v-if="getSortDirection('orderId')" class="sort-indicator">
              {{ getSortIndex('orderId') }}
              <i :class="['fas',getSortDirection('orderId') === 'asc' ? 'fa-sort-up' : 'fa-sort-down']"></i>
            </span>
          </th>
          <th @click="toggleSort('status')" style="cursor: pointer">
            Status
            <span v-if="getSortDirection('status')" class="sort-indicator">
              {{ getSortIndex('status') }}
              <i :class="['fas',getSortDirection('status') === 'asc' ? 'fa-sort-up' : 'fa-sort-down']"></i>
            </span>
          </th>
          <th @click="toggleSort('created')" style="cursor: pointer">
            Created
            <span v-if="getSortDirection('created')" class="sort-indicator">
              {{ getSortIndex('created') }}
              <i :class="['fas',getSortDirection('created') === 'asc' ? 'fa-sort-up' : 'fa-sort-down']"></i>
            </span>
          </th>
          <th @click="toggleSort('updated')" style="cursor: pointer">
            Updated
            <span v-if="getSortDirection('updated')" class="sort-indicator">
              {{ getSortIndex('updated') }}
              <i :class="['fas',getSortDirection('updated') === 'asc' ? 'fa-sort-up' : 'fa-sort-down']"></i>
            </span>
          </th>
          <th>
            <!-- >ACTIONS -->
            <button @click="clearSorting" class="btn btn-sm btn-outline-secondary">Clear Sort</button>
          </th>
        </tr>
        </thead>
        <tbody>
          <template v-for="(result, index) in results" :key="index">
            <tr>
              <!-- >OrderId -->
              <td>{{ result.orderId }}</td>

              <!-- Status -->
              <!--  TODO: status should come back as UPPERCASE-->
              <td>{{ result.status }}</td>

              <!-- Created -->
              <!--  TODO: propertyKey should be createdDate-->
              <td>{{ result.createdDate }}</td>

              <!--Updated -->
              <!--  TODO: propertyKey should be updatedDate-->
              <td>{{ result.updatedDate }}</td>
              <!-- Actions -->
              <td>
                <!-- SHOW DETAILS -->
                <button v-if="isComplete(result.status)" @click="toggleDetails(result.orderId)"
                        class="btn btn-sm"
                        :class="{'disabled': !isComplete(result.status), 'btn-primary': !isDetailsExpanded(result.orderId), 'btn-secondary': isDetailsExpanded(result.orderId)}">
                  {{ isDetailsExpanded(result.orderId) ? 'Hide' : 'Details' }}
                </button>

                <!-- EXPIRE -->
                <button v-if="isInitiated(result.status)" @click="expireOrder(result.orderId)"
                        class="btn btn-sm btn-warning">
                  Expire
                </button>

                <!-- DELETE -->
                <button v-if="isExpired(result.status)" @click="deleteOrder(result.orderId)"
                        class="btn btn-sm btn-danger">
                  DELETE
                </button>
              </td>
            </tr>

            <!-- Order Details -->
            <tr v-if="isDetailsExpanded(result.orderId)">
              <td colspan="6">
                <order-detail :orderId="result.orderId"></order-detail>
              </td>
            </tr>
          </template>
        </tbody>
      </table>
    </div>
    <div v-else>
      No results found
    </div>
  </div>

  <!-- TODO: this goes in a pagination component -->
  <nav aria-label="Page navigation">
    <ul class="pagination justify-content-center">
      <!-- Previous button -->
      <li class="page-item" :class="{ disabled: page === 1 }">
        <a class="page-link" href="#" @click="gotoPage(page - 1)" aria-label="Previous">
          <span aria-hidden="true">&laquo;</span>
        </a>
      </li>
      <!-- Page numbers -->
      <li v-for="pageNum in displayedPages"
          :key="pageNum"
          class="page-item"
          :class="{ active: pageNum === page }">
        <a class="page-link"
           href="#"
           @click="gotoPage(pageNum)">{{ pageNum }}</a>
      </li>
      <!-- Next button -->
      <li class="page-item" :class="{ disabled: page === pages }">
        <a class="page-link" href="#" @click="gotoPage(page + 1)" aria-label="Next">
          <span aria-hidden="true">&raquo;</span>
        </a>
      </li>
    </ul>
  </nav>
</template>
<script>
import {useStore} from "vuex";
import {
  computed,
  onBeforeMount,
  ref
} from "vue";
import LoadingModal from "@/components/LoadingModal.vue";
import OrderDetail from "@/components/orders/OrderDetail.vue";

export default {
  components: {
    OrderDetail,
    LoadingModal
  },
  setup() {

    const store = useStore()

    const selectedOrder = ref(null)

    const isLoading = ref(false)
    const sortFields = ref([
      {orderId: 'desc'}
    ])

    const results = ref([]);
    const page = ref(1);
    const pages = ref(1);
    const startPage = ref(1);
    const endPage = ref(1);
    const displayedPages = computed(() => {
      const pages = [];
      for (let i = startPage.value; i <= endPage.value; i++) {
        pages.push(i);
      }
      return pages;
    });

    onBeforeMount(async () => {
      const searchResults = await store.getters['orders/searchResults'];
      if (searchResults) {
        results.value = searchResults.pages[page.value];
        pages.value = searchResults.totalPages;
        startPage.value = 1;
        endPage.value = searchResults.totalPages;

        sortResults();
      } else {
        results.value = [];
        pages.value = 1;
        startPage.value = 1;
        endPage.value = 1;
      }
    });

    const gotoPage = async function (pageNumber) {
      try {
        isLoading.value = true
        await store.dispatch('orders/searchOrders', {
          page: pageNumber
        });

        // load results from store
        const searchResults = await store.getters['orders/searchResults'];
        page.value = pageNumber
        results.value = searchResults.pages[page.value];
      } finally {
        isLoading.value = false
      }
    }

    const isComplete = function(status) {
      // todo: Return UPPERCASE values only
      return status === 'COMPLETE' || status === 'SHIPPED'
    }

    const isInitiated = function(status) {
      return status === 'INITIATED'
    }

    const isExpired = function(status) {
      return status === 'EXPIRED'
    }

    const expandedDetails = ref([])
    const toggleDetails = async function(orderId) {
      if (isDetailsExpanded(orderId)) {
        expandedDetails.value = expandedDetails.value.filter((e) => e !== orderId)
      } else {
        expandedDetails.value.push(orderId)
      }
    }
    const isDetailsExpanded = function(orderId) {
      return expandedDetails.value.find((e) => e === orderId)
    }

    /**
     * TODO: Not implemented
     */
    const expireOrder = async function(/*orderId*/) {
      // store.dispatch('orders/expire', orderId)
      // reload search results
    }

    /**
     * TODO: Not implemented
     */
    const deleteOrder = async function(/*orderId*/) {
      // store.dispatch('orders/expire', orderId)
      // reload search results
    }

    const toggleSort = function(field) {
      const existingSort = sortFields.value.find((s) => s.field === field)
      if (existingSort) {
        existingSort.order = existingSort.order === 'asc' ? 'desc' : 'asc'
      } else {
        sortFields.value.push({
          field: field,
          order: 'asc'
        })
      }

      sortResults();
    }

    const sortResults = function () {
      results.value.sort((a, b) => {
        for (const sort of sortFields.value) {
          if (sort.field === 'orderId') {
            return sort.order === 'asc'
                ? parseInt(a[sort.field]) > parseInt(b[sort.field]) ? 1 : -1
                : parseInt(a[sort.field]) < parseInt(b[sort.field]) ? 1 : -1;
          } else {
            // Handle non-date fields
            if (a[sort.field] !== b[sort.field]) {
              return sort.order === 'asc'
                  ? a[sort.field] > b[sort.field] ? 1 : -1
                  : a[sort.field] < b[sort.field] ? 1 : -1;
            }
          }
        }
        return 0;
      });
    }

    const getSortIndex = function (field) {
      const index = sortFields.value.findIndex((s) => s.field === field)
      return index !== -1 ? index + 1 : null;
    }

    const getSortDirection = function (field) {
      const sort = sortFields.value.find((s) => s.field === field)
      return sort ? sort.order : null;
    }

    const clearSorting = function () {
      sortFields.value = [/*{
        field: 'created',
        order: 'desc'
      }*/]
      sortResults()
    }

    const getOrderDetail = async function (orderId) {
      try {
        isLoading.value = true
        return await store.dispatch('orders/loadOrderDetail', orderId);
      } finally {
        isLoading.value = false
      }
    }

    return {
      isLoading,

      selectedOrder,
      isComplete,
      isInitiated,
      isExpired,
      toggleDetails,
      isDetailsExpanded,
      expireOrder,
      deleteOrder,

      toggleSort,
      sortResults,
      getSortIndex,
      getSortDirection,
      clearSorting,

      results,
      pages,
      page,
      gotoPage,
      displayedPages,
      getOrderDetail
    }
  }
}
</script>
<style scoped>
th[style*="cursor: pointer"] {
  user-select: none;
}

th[style*="cursor: pointer"]:hover {
  background-color: #f8f9fa;
}

.sort-indicator {
  margin-left: 4px;
  font-size: 0.8em;
}

@media print {
  body * {
    visibility: hidden;
  }

  .modal-body,
  .modal-body * {
    visibility: visible;
  }

  .modal-body {
    position: absolute;
    left: 0;
    top: 0;
  }

  .modal-header,
  .modal-footer {
    display: none;
  }
}
</style>
