<template>
  <div class="modal-content">
    <div class="modal-header bg-light">
      <h5 class="modal-title">Packing Slip</h5>
      <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
    </div>
    <div class="modal-body" id="packingSlipContent">
      <!-- Packing slip content -->
      <div class="packing-slip bg-white p-4 border border-1 rounded">
        <div class="d-flex justify-content-between mb-4">
          <div>
            <h4>Order #{{ order.orderId }}</h4>
            <div>Order Date: {{ orderDate }}</div>
          </div>
          <div>
            <h5>Ship To:</h5>
            <p class="h5 p-0 mb-1">{{ order.shipToName }}</p>
            <p class="h5 p-0 mb-1">{{ order.shipToAddress }}</p>
            <p class="h5 p-0 mb-1">{{ order.shipToCity }}, {{ order.shipToState }}  {{ order.shipToPostalCode }}</p>
            <p v-if="order.shipToCountry !== 'US'" class="h5 p-0 mb-1">{{ order.shipToCity }}, {{ order.shipToState }}  {{ order.shipToPostalCode }}</p>
          </div>
        </div>

        <table class="table">
          <thead>
          <tr>
            <th>Item</th>
            <th>Quantity</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="item in order.items" :key="item.productId">
            <td>{{ item.name }}</td>
            <td>{{ item.qty }}</td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
      <button type="button" class="btn btn-primary" @click="printPackingSlip">
        <i class="bi bi-printer"></i> Print
      </button>
    </div>
  </div>
</template>

<script>
import {toRefs} from "vue";

function formatDate(date) {
  const d = new Date(date);

  const year = d.getFullYear();
  const month = String(d.getMonth() + 1).padStart(2, '0'); // Months are 0-based
  const day = String(d.getDate()).padStart(2, '0');

  return `${year}-${month}-${day}`;
}

export default {
  props: {
    order: {
      type: Object,
      required: true
    }
  },
  setup(props) {

    const {order} = toRefs(props);

    // TODO: format as MMM dd, YYYY
    const orderDate = formatDate(order.value.created);

    const printPackingSlip = () => {
      const printContent = document.querySelector('.packing-slip')
      const WinPrint = window.open('', '', 'width=800,height=900')

      WinPrint.document.write(`
    <html lang="en">
      <head>
        <title>Packing Slip</title>
        <style>
          @media print {
            .packing-slip {
              width: 4in;
              height: 6in;
              padding: 0.25in;
              margin: 0 auto;
              border: 1px solid #000;
            }
            .packing-info {
              font-family: Arial, sans-serif;
              font-size: 12pt;
            }
            .from-address, .to-address {
              margin-bottom: 20px;
            }
            .to-address {
              font-size: 14pt;
              font-weight: bold;
            }
            .order-details {
              margin-top: 20px;
              font-size: 10pt;
            }
            h6 {
              margin: 0;
              font-size: 10pt;
              text-transform: uppercase;
            }
            p {
              margin: 2px 0;
            }
          }
        </style>
      </head>
      <body>
        ${printContent.innerHTML}
      </body>
    </html>
  `)

      WinPrint.document.close()
      WinPrint.focus()
      setTimeout(() => {
        WinPrint.print()
        WinPrint.close()
      }, 250)
    }

    return {
      orderDate,
      printPackingSlip,
    }
  }
}
</script>
