<template>
  <div class="container">
    <base-dialog :show="!!error" title="An error occurred" @close="handleError">
      <p>{{ error }}</p>
    </base-dialog>
    <base-dialog fixed :show="isLoading" title="Authenticating...">
      <base-spinner></base-spinner>
    </base-dialog>

    <div class="container">
      <form v-if="!needsConfirmation && !needsPassword" @submit.prevent="submitForm">
        <div class="mb-3 input-group">
          <span class="input-group-text">Email</span>
          <input type="email" class="form-control" v-model.trim="email"/>
        </div>
        <div class="mb-3 input-group">
          <span class="input-group-text">Password</span>
          <input type="password" class="form-control" v-model.trim="password"/>
        </div>

        <div class="mb-3 d-flex justify-content-between">
          <input type="submit" value="Log in" class="btn btn-primary btn-block" :class="disable">
        </div>
      </form>

      <form v-else-if="needsPassword" @submit.prevent="changePassword">
        <h3>Enter a new Password</h3>
        <div class="mb-3 input-group">
          <span class="input-group-text">Password</span>
          <input type="password" class="form-control" v-model.trim="newPassword"/>
        </div>
        <input type="submit" value="Change Password" class="btn btn-primary btn-block" :class="disable">
      </form>
    </div>
  </div>
</template>

<script>
import {computed, ref} from "vue";
import {useStore} from "vuex";
import {useRoute, useRouter} from "vue-router";
import BaseDialog from "@/components/BaseDialog.vue";
import BaseSpinner from "@/components/BaseSpinner.vue";

export default {
  components: {BaseSpinner, BaseDialog},
  setup() {

    const store = useStore();
    const router = useRouter();
    const route = useRoute();

    const needsConfirmation = ref(false);
    const needsPassword = ref(false);
    const needsPasswordSession = ref(null);
    const isValid = ref(true);
    const isLoading = ref(false);

    const code = ref('');
    const codeSentTo = ref('');
    const newPassword = ref('');

    const disable = computed(() => {
      return (!isValid.value || isLoading.value) ? 'disabled' : '';
    })

    const email = ref('');
    const password = ref('');
    const error = ref('');

    const submitForm = async function () {
      error.value = '';
      if (email.value === '' || password.value === '') {
        error.value = 'Please enter a valid email and password';
      } else {
        isLoading.value = true;
        await store.dispatch(
            'auth/login',
            {
              username: email.value,
              password: password.value
            }
        ).then((result) => {
          isLoading.value = false;
          if (result.result === 'authenticated') {
            email.value = '';
            password.value = '';
            const redirectUrl = '/' + (route.query.redirect || '')
            router.replace(redirectUrl)
          } else if (result.result === 'confirmIdentity') {
            needsConfirmation.value = true;
          } else if (result.result === 'new_password') {
            needsPassword.value = true;
            needsPasswordSession.value = result.session
          } else {
            error.value = 'unexpected result ' + result;
          }

        }).catch(err => {
          console.error('error: ' + err);
          error.value = err.message;
        })

        isLoading.value = false;
      }
    }

    const changePassword = async function () {
      isLoading.value = true;

      const payload = {
        username: email.value,
        newPassword: newPassword.value,
        session: needsPasswordSession.value
      }

      await store.dispatch('auth/newPasswordChallenge',payload)
          .then(() => {
            store.dispatch('auth/login', {
              username: email.value,
              password: newPassword.value,
            }).then(() => {
              const redirectUrl = '/'
              router.replace(redirectUrl)
            }).catch((err) => {
              console.error(err)
              error.value = err.message
            })
      }).catch(err => {
        console.error('error: ' + err);
        error.value = err.message;
      })

      isLoading.value = false;
    }

    const handleError = function () {
      error.value = null
    }

    return {
      error,
      isLoading,
      email,
      password,

      needsPassword,
      needsPasswordSession,
      newPassword,

      needsConfirmation,
      code,
      codeSentTo,

      disable,

      submitForm,
      changePassword,
      handleError
    }
  }
}
</script>