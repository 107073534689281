<template>
  <div class="container">
    <section>
        <h2>Page Not Found</h2>
        <p>
          Page Not Found
        </p>
    </section>
  </div>
</template>

<script>
export default {
  computed: {
    authenticated() {
      return this.$store.getters['auth/isAuthenticated']
    }
  }
}
</script>