<template>

  <loading-modal v-if="isLoading" title="Searching..."/>

  <div class="container">
    <h2>Communications Search Results</h2>

    <div class="mb-3">
      <router-link :to="{name: 'comms-search'}" class="btn btn-outline-secondary">
        <i class="fas fa-arrow-left"></i> Back to Search
      </router-link>
    </div>

    <div v-if="results">

      <table class="table">
        <thead>
          <tr>
            <th @click="toggleSort('username')" style="cursor: pointer">
              Email
              <span v-if="getSortDirection('username')" class="sort-indicator">
              {{ getSortIndex('email') }}
              <i :class="['fas',getSortDirection('username') === 'asc' ? 'fa-sort-up' : 'fa-sort-down']"></i>
              </span>
            </th>
            <th @click="toggleSort('status')" style="cursor: pointer">
              Status
              <span v-if="getSortDirection('status')" class="sort-indicator">
                {{ getSortIndex('status') }}
                <i :class="['fas',getSortDirection('status') === 'asc' ? 'fa-sort-up' : 'fa-sort-down']"></i>
              </span>
            </th>
            <th @click="toggleSort('updated')" style="cursor: pointer">
              Updated
              <span v-if="getSortDirection('updated')" class="sort-indicator">
                {{ getSortIndex('updated') }}
                <i :class="['fas',getSortDirection('updated') === 'asc' ? 'fa-sort-up' : 'fa-sort-down']"></i>
              </span>
            </th>
            <th>
              <button @click="clearSorting" class="btn btn-sm btn-outline-secondary">Clear Sort</button>
            </th>
          </tr>
        </thead>
        <tbody>
          <template v-for="(result, index) in results" :key="index">
            <tr>
              <td>{{ result.username }}</td>
              <td>{{ result.status }}</td>
              <td>{{ result.updatedDate }}</td>
              <td>
                <button @click="toggleExpand(result.communicationId)"
                        class="btn btn-sm"
                        :class="{'btn-primary': !isExpanded(result.communicationId), 'btn-secondary': isExpanded(result.communicationId)}">
                  {{ isExpanded(result.communicationId) ? 'Hide' : 'Show' }}
                </button>
              </td>
            </tr>
            <tr v-if="isExpanded(result.communicationId)">
              <td colspan="4">
                <email-detail :communicationId="result.communicationId"></email-detail>
              </td>
            </tr>
          </template>
        </tbody>
      </table>
    </div>
    <div v-else>
      No results found
    </div>
  </div>

  <nav aria-label="Page navigation">
    <ul class="pagination justify-content-center">
      <!-- Previous button -->
      <li class="page-item" :class="{ disabled: page === 1 }">
        <a class="page-link" href="#" @click="gotoPage(page - 1)" aria-label="Previous">
          <span aria-hidden="true">&laquo;</span>
        </a>
      </li>
      <!-- Page numbers -->
      <li v-for="pageNum in displayedPages"
          :key="pageNum"
          class="page-item"
          :class="{ active: pageNum === page }">
        <a class="page-link"
           href="#"
           @click="gotoPage(pageNum)">{{ pageNum }}</a>
      </li>
      <!-- Next button -->
      <li class="page-item" :class="{ disabled: page === pages }">
        <a class="page-link" href="#" @click="gotoPage(page + 1)" aria-label="Next">
          <span aria-hidden="true">&raquo;</span>
        </a>
      </li>
    </ul>
  </nav>
</template>

<script>
import { useStore } from 'vuex'
import {computed, onBeforeMount, ref} from 'vue'
import LoadingModal from "@/components/LoadingModal.vue";
import EmailDetail from "@/components/comms/EmailDetail.vue";

export default {
  components: {
    EmailDetail,
    LoadingModal
  },
  setup() {

    const store = useStore()

    const isLoading = ref(false)
    const sortFields = ref([
      /*{field: 'created', order: 'desc'}*/
    ])

    const results = ref([]);
    const page = ref(1);
    const pages = ref(1);
    const startPage = ref(1);
    const endPage = ref(1);
    const displayedPages = computed(() => {
      const pages = [];
      for (let i = startPage.value; i <= endPage.value; i++) {
        pages.push(i);
      }
      return pages;
    });

    onBeforeMount(async () => {
      const searchResults = await store.getters['email/searchResults'];
      if (searchResults) {
        results.value = searchResults.pages[page.value];
        pages.value = searchResults.totalPages;
        startPage.value = 1;
        endPage.value = searchResults.totalPages;

        sortResults();
      } else {
        results.value = [];
        pages.value = 1;
        startPage.value = 1;
        endPage.value = 1;
      }
    });

    const expanded = ref([])
    const toggleExpand = function(email) {
      if (isExpanded(email)) {
        expanded.value = expanded.value.filter((e) => e !== email)
      } else {
        expanded.value.push(email)
      }
    }
    const isExpanded = function(email) {
      return expanded.value.find((e) => e === email)
    }

    const toggleSort = function(field) {
      const existingSort = sortFields.value.find((s) => s.field === field)
      if (existingSort) {
        existingSort.order = existingSort.order === 'asc' ? 'desc' : 'asc'
      } else {
        sortFields.value.push({
          field: field,
          order: 'asc'
        })
      }

      sortResults();
    }

    const sortResults = function () {
      results.value.sort((a, b) => {
        for (const sort of sortFields.value) {
          /*if (sort.field.includes('date') || sort.field === 'created' || sort.field === 'updated') {
            const aDate = new Date(a[sort.field].toLocaleString('en-US', {
              timeZone: 'America/Los_Angeles'
            }));
            const bDate = new Date(b[sort.field].toLocaleString('en-US', {
              timeZone: 'America/Los_Angeles'
            }));

            if (aDate.getTime() !== bDate.getTime()) {
              return sort.direction === 'asc' ? aDate - bDate : bDate - aDate;
            }
          } else {*/
          // Handle non-date fields
          if (a[sort.field] !== b[sort.field]) {
            return sort.order === 'asc'
                ? a[sort.field] > b[sort.field] ? 1 : -1
                : a[sort.field] < b[sort.field] ? 1 : -1;
          }
          /*}*/
        }
        return 0;
      });
    }

    const getSortIndex = function (field) {
      const index = sortFields.value.findIndex((s) => s.field === field)
      return index !== -1 ? index + 1 : null;
    }

    const getSortDirection = function (field) {
      const sort = sortFields.value.find((s) => s.field === field)
      return sort ? sort.order : null;
    }

    const clearSorting = function () {
      sortFields.value = [/*{
        field: 'created',
        order: 'desc'
      }*/]
      sortResults()
    }

    const gotoPage = async function (pageNumber) {
      try {
        isLoading.value = true
        await store.dispatch('email/searchEmails', {
          page: pageNumber
        });

        // load results from store
        const searchResults = await store.getters['email/searchResults'];
        page.value = pageNumber
        results.value = searchResults.pages[page.value];
      } finally {
        isLoading.value = false
      }
    }

    return {
      isLoading,
      toggleExpand,
      isExpanded,
      toggleSort,
      getSortIndex,
      getSortDirection,
      clearSorting,

      results,
      pages,
      page,
      gotoPage,
      displayedPages,
    }
  }
}
</script>