<template>
  <loading-modal v-if="isLoading" title="Searching..."/>

  <div class="container">
    <h2>Products Search</h2>

    <div class="row">
      <div class="col-12">
        <div class="form-group">

          <form @submit.prevent="submitForm">
            <div class="mb-3 input-group">
              <span class="input-group-text">Type</span>
              <select class="form-select" v-model="type">
                <option value="">ALL</option>
                <option v-for="type in types" :key="type" :value="type">{{ type }}</option>
              </select>
            </div>
            <div class="mb-3 input-group">
              <span class="input-group-text">Group</span>
              <input type="text" class="form-control" v-model.trim="group"/>
            </div>
            <div class="mb-3 input-group">
              <span class="input-group-text">Name</span>
              <input type="text" class="form-control" v-model.trim="name"/>
            </div>
            <div class="mb-3 d-flex justify-content-between">
              <input type="button" value="Clear" class="btn bg-danger text-white btn-block" :class="isLoading ? 'disabled' : ''" @click="clearForm">
              <input type="submit" value="Submit" class="btn btn-primary btn-block">
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Swal from 'sweetalert2'
import LoadingModal from "@/components/LoadingModal.vue";
import {
  onBeforeMount,
  ref
} from "vue";
import {useStore} from "vuex";
import {useRouter} from "vue-router";

export default {
  components: {LoadingModal},
  setup() {

    const store = useStore();
    const router = useRouter();

    const isLoading = ref(false)

    const types = ref([]);

    const type = ref('');
    const group = ref('');
    const name = ref('');

    onBeforeMount(async () => {
      try {
        isLoading.value = true;
        await store.dispatch('products/clearSearch');
        const typeOptions = await store.dispatch('products/loadTypes');
        types.value = typeOptions.map((type) => type.toUpperCase());
      } catch (error) {
        console.error('Exception: ' + JSON.stringify(error))
        new Swal({
          title: 'Error!',
          text: 'We encountered a problem: ' + error.message,
          icon: 'error'
        });
        types.value = [
            'Books',
            'Calendars',
            'Cards'
        ].map((type) => type.toUpperCase());
      } finally {
        isLoading.value = false;
      }
    })

    const submitForm = async () => {
      try {
        const queryParams = {}
        if (type.value) queryParams.type = type.value;
        if (group.value) queryParams.group = group.value;
        if (name.value) queryParams.name = name.value;

        isLoading.value = true;

        const results = await store.dispatch('products/searchProducts', {filters: queryParams});
        if (Object.keys(results).length > 0) {
          router.push({
            name: 'products-results',
          });
        } else {
          // show dialog no results found
          new Swal({
            title: 'No Results',
            text: 'No results found for your search criteria.',
            icon: 'info'
          });
        }
      } catch (error) {
        console.error('Exception: ' + JSON.stringify(error))
        new Swal({
          title: 'Error!',
          text: 'We encountered a problem: ' + error.message,
          icon: 'error'
        });
      } finally {
        isLoading.value = false;
      }
    }

    const clearForm = () => {
      type.value = ''
      group.value = ''
      name.value = ''
    }

    return {
      isLoading,
      types,
      type,
      group,
      name,

      submitForm,
      clearForm,
    }
  },
}
</script>