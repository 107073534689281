<template>
  <div class="result-view">
    <div class="row w-100">
      <div class="d-flex w-100">
        <div class="flex-grow-1">
          <!-- Format row -->
          <div class="row align-items-center mb-2">
            <div class="col-2 text-end fw-bolder">
              <label for="formatName" class="form-label mb-0 d-inline-block">Format:</label>
            </div>
            <div class="col-10 text-start">
              <p class="mb-0 d-inline-block">{{formatGroup.format}}</p>
            </div>
          </div>
          <!-- Description row -->
          <div class="row align-items-center mb-2">
            <div class="col-2 text-end fw-bolder">
              <label for="formatDescription" class="form-label mb-0 d-inline-block">Description:</label>
            </div>
            <div class="col-10 text-start">
              <p class="mb-0 d-inline-block">{{formatGroup.formatDescription}}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
<!--  <div><p class="text-start">{{formatGroup.format}}</p></div>
  <div><p class="text-start">{{formatGroup.formatDescription}}</p></div>-->
<!--  <div class="table">
    <div v-for="printResult in formatGroup.prints" :key="printResult.printId">
      <search-result-detail :print-result="printResult"/>
    </div>
  </div>-->
</template>
<script>
// import SearchResultDetail from "@/components/search/SearchResultDetail.vue";

export default {
  components: {
    // SearchResultDetail
  },
  props: {
    formatGroup: {
      type: Object,
      required: true
    }
  }
}
</script>